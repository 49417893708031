import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/Context';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaSearch, FaCheck, FaTimes, FaUserShield } from 'react-icons/fa';

const AdminUsers = () => {
  const { listUsers, deleteUser, updateUser, users, loading, error } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    listUsers();
  }, []);

  useEffect(() => {
    if (users) {
      setFilteredUsers(
        users.filter((user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [users, searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    // The filtering is already done in the useEffect above
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      await deleteUser(userId);
    }
  };

  const handleEdit = (user) => {
    setEditingUser({ ...user });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateUser(editingUser);
    setEditingUser(null);
  };

  const toggleAdmin = async (user) => {
    const updatedUser = { ...user, isAdmin: !user.isAdmin };
    await updateUser(updatedUser);
  };

  if (loading) return <LoadingMessage>Loading users...</LoadingMessage>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <Container>
      <Header>
        <h1>User Management</h1>
      </Header>

      <SearchForm onSubmit={handleSearch}>
        <SearchInput
          type="text"
          placeholder="Search users by name or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchButton type="submit">
          <FaSearch />
          Search
        </SearchButton>
      </SearchForm>

      {editingUser && (
        <EditForm onSubmit={handleUpdate}>
          <h2>Edit User</h2>
          <FormGroup>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={editingUser.name}
              onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={editingUser.email}
              onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="isAdmin">
              <input
                type="checkbox"
                id="isAdmin"
                checked={editingUser.isAdmin}
                onChange={(e) => setEditingUser({ ...editingUser, isAdmin: e.target.checked })}
              />
              Admin
            </label>
          </FormGroup>
          <ButtonGroup>
            <Button type="submit">Update</Button>
            <Button type="button" onClick={() => setEditingUser(null)}>Cancel</Button>
          </ButtonGroup>
        </EditForm>
      )}

      {filteredUsers.length === 0 ? (
        <EmptyState>No users found.</EmptyState>
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Admin</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {user.isAdmin ? (
                    <StatusIcon green><FaCheck /></StatusIcon>
                  ) : (
                    <StatusIcon red><FaTimes /></StatusIcon>
                  )}
                </td>
                <td>
                  <ActionButton onClick={() => handleEdit(user)} title="Edit User">
                    <FaEdit />
                  </ActionButton>
                  <ActionButton onClick={() => toggleAdmin(user)} title="Toggle Admin Status">
                    <FaUserShield />
                  </ActionButton>
                  <ActionButton onClick={() => handleDelete(user._id)} title="Delete User">
                    <FaTrash />
                  </ActionButton>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.header`
  margin-bottom: 2rem;

  h1 {
    font-size: 2rem;
    color: #ffffff;
  }
`;

const SearchForm = styled.form`
  display: flex;
  margin-bottom: 2rem;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #2a2f3e;
  border-radius: 4px 0 0 4px;
  background-color: #1e2330;
  color: #ffffff;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #357abd;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #1e2330;
  border-radius: 8px;
  overflow: hidden;

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #2a2f3e;
  }

  th {
    background-color: #2a2f3e;
    font-weight: bold;
    color: #ffffff;
  }

  td {
    color: #cccccc;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: #4a90e2;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 1rem;
  padding: 0.25rem;
  margin-right: 0.5rem;

  &:hover {
    color: #357abd;
  }
`;

const StatusIcon = styled.span`
  color: ${props => props.green ? '#28a745' : props.red ? '#dc3545' : 'inherit'};
`;

const EditForm = styled.form`
  background-color: #2a2f3e;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;

  h2 {
    color: #ffffff;
    margin-bottom: 1rem;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #1e2330;
    border-radius: 4px;
    background-color: #1e2330;
    color: #ffffff;
  }

  input[type="checkbox"] {
    margin-right: 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #357abd;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #ffffff;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #dc3545;
`;

const EmptyState = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #6c757d;
`;

export default AdminUsers;