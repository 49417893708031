import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useCart, useProducts, useUser } from '../../context/Context';
import ShopObjectCard from "../../components/ShopObjectCard/ShopObjectCard";
import ShopObjectCardsContainer from "../../components/ShopObjectCardsContainer/ShopObjectCardsContainer";
import {
  StyledContainer,
  StyledHeading,
  StyledCartContent,
  StyledCartItem,
  StyledCartImage,
  StyledCartInfo,
  StyledCartQuantity,
  StyledCartTotal,
  StyledButton,
  StyledEmptyCart,
  StyledQuantityAdjust,
  StyledSaveForLater,
  StyledSuggestionsTitle,
  StyledCartItemDetails,
  StyledCartItemActions,
} from "./StyledCart";

const CartPage = () => {
    const navigate = useNavigate();
    const { cartItems = [], addToCart, removeFromCart, savedItems = [] } = useCart();
    const { products, loading, error, listTopProducts } = useProducts();
    const [subtotal, setSubtotal] = useState(0);
    const [topProductsFetched, setTopProductsFetched] = useState(false);
    const { user } = useUser();
  
    useEffect(() => {
      if (!topProductsFetched) {
        listTopProducts();
        setTopProductsFetched(true);
      }
    }, [listTopProducts, topProductsFetched]);
  
    useEffect(() => {
      setSubtotal(calculateSubtotal());
    }, [cartItems]);
  
    const calculateSubtotal = () => {
      return cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2);
    };
  
    const removeFromCartHandler = (id) => {
      removeFromCart(id);
    };
  
    const checkoutHandler = () => {
      if (user) {
          navigate('/checkout');
      } else {
          navigate('/login?redirect=checkout');
      }
    };
  
    const handleQuantityChange = (id, newQty) => {
      const item = cartItems.find(item => item.product === id);
      if (item && newQty > 0 && newQty <= item.countInStock) {
        addToCart(id, newQty);
      }
    };

  console.log('Cart Items:', cartItems);
  console.log('Products:', products);
  
  return (
    <StyledContainer>
      <StyledHeading>Shopping Cart</StyledHeading>
      {cartItems.length === 0 ? (
        <StyledEmptyCart>
          <p>Your cart is empty</p>
          <StyledButton as={Link} to="/shop">Continue Shopping</StyledButton>
        </StyledEmptyCart>
      ) : (
        <StyledCartContent>
          {cartItems.map((item) => (
            <StyledCartItem key={item.product}  >
              <StyledCartImage src={process.env.REACT_APP_API_URL + item.image[0]} alt={item.name} />
              <StyledCartItemDetails>
                <StyledCartInfo to={`/product/${item.product}`}>{item.name}</StyledCartInfo>
                <StyledCartInfo>${item.price}</StyledCartInfo>
                <StyledQuantityAdjust>
                  <button 
                    onClick={() => handleQuantityChange(item.product, item.qty - 1)}
                    disabled={item.qty <= 1}
                  >
                    -
                  </button>
                  <StyledCartQuantity
                    as="select"
                    value={item.qty}
                    onChange={(e) => handleQuantityChange(item.product, Number(e.target.value))}
                  >
                    {[...Array(item.countInStock).keys()].map((x) => (
                      <option key={x + 1} value={x + 1}>
                        {x + 1}
                      </option>
                    ))}
                  </StyledCartQuantity>
                  <button 
                    onClick={() => handleQuantityChange(item.product, item.qty + 1)}
                    disabled={item.qty >= item.countInStock}
                  >
                    +
                  </button>
                </StyledQuantityAdjust>
              </StyledCartItemDetails>
              <StyledCartItemActions>
                <StyledButton onClick={() => removeFromCartHandler(item.product)}>
                  Remove
                </StyledButton>
              </StyledCartItemActions>
            </StyledCartItem>
          ))}
          <StyledCartTotal>
            <p>Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items</p>
            <h3>${subtotal}</h3>
            <StyledButton
              disabled={cartItems.length === 0}
              onClick={checkoutHandler}
            >
              Proceed To Checkout
            </StyledButton>
          </StyledCartTotal>
        </StyledCartContent>
      )}
      {savedItems.length > 0 && (
        <div>
          <h2>Saved for later</h2>
          {savedItems.map(item => (
            <StyledCartItem key={item.product}>
              <StyledCartImage src={process.env.REACT_APP_API_URL + item.image[0]} alt={item.name} />
              <StyledCartItemDetails>
                <StyledCartInfo>{item.name}</StyledCartInfo>
                <StyledCartInfo>${item.price}</StyledCartInfo>
              </StyledCartItemDetails>
              <StyledCartItemActions>
                <StyledButton onClick={() => addToCart(item.product, 1)}>
                  Move to Cart
                </StyledButton>
              </StyledCartItemActions>
            </StyledCartItem>
          ))}
        </div>
      )}
      {products.length > 0 && (
        <>
          <StyledSuggestionsTitle>You might also like</StyledSuggestionsTitle>
          <ShopObjectCardsContainer>
            {products.map((product) => (
              <ShopObjectCard
                key={product._id}
                id={product._id}
                img={product.image}
                name={product.name}
                price={product.price}
                to={`/product/${product._id}`}
                countInStock={product.countInStock}
              />
            ))}
          </ShopObjectCardsContainer>
        </>
      )}
    </StyledContainer>
  );
};

export default CartPage;