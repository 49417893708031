import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/Context';
import {
  StyledContainer,
  StyledHeading,
  StyledDashboardGrid,
  StyledDashboardCard,
  StyledCardTitle,
  StyledCardContent
} from './StyledAdmin';

const AdminDashboard = () => {
  const { user } = useUser();

  if (!user || !user.isAdmin) {
    return <p>Access denied. You must be an admin to view this page.</p>;
  }

  return (
    <StyledContainer>
      <StyledHeading>Admin Dashboard</StyledHeading>
      <StyledDashboardGrid>
        <Link to="/admin/products" style={{ textDecoration: 'none' }}>
          <StyledDashboardCard>
            <StyledCardTitle>Products</StyledCardTitle>
            <StyledCardContent>
              <Link to="/admin/products">Manage Products</Link>
            </StyledCardContent>
          </StyledDashboardCard>
        </Link>
        <Link to="/admin/orders" style={{ textDecoration: 'none' }}>
          <StyledDashboardCard>
            <StyledCardTitle>Orders</StyledCardTitle>
            <StyledCardContent>
              <Link to="/admin/orders">Manage Orders</Link>
            </StyledCardContent>
          </StyledDashboardCard>
        </Link>
        <Link to="/admin/users" style={{ textDecoration: 'none' }}>
          <StyledDashboardCard>
            <StyledCardTitle>Users</StyledCardTitle>
            <StyledCardContent>
              <Link to="/admin/users">Manage Users</Link>
            </StyledCardContent>
          </StyledDashboardCard>
        </Link>
      </StyledDashboardGrid>
    </StyledContainer>
  );
};

export default AdminDashboard;