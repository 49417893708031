import {
  StyledSection,
  StyledContentContainer,
  StyledHeading,
  StyledParagraph,
  StyledButton,
  StyledEarthAndMoonContainer,
  StyledWaveBackground
} from "./StyledHero";
import logo from "../../imges/SlashTechLogo.png"


const HeroSection = () => (
  <StyledSection>
    <StyledEarthAndMoonContainer>
      <img src={logo} alt="Logo" />
    </StyledEarthAndMoonContainer>
    <StyledContentContainer>
      <StyledHeading> 
        Growing Ideas,<br/>
        Harvesting Knowledge 
      </StyledHeading>
      <StyledParagraph> 
        Unlock your organisation's technology potential with SlashTech’s thorough assessments and tailored solutions.
        Develop commercial technology initiatives with confidence, supported by SlashTech’s comprehensive services that meet your diverse needs and objectives.
        Empower your educational institution with expert consulting and innovative solutions designed to enhance learning experiences and outcomes. 
      </StyledParagraph>
      <br/>
      <StyledButton to="/services">Explor all services</StyledButton>
    </StyledContentContainer>
    
    <StyledWaveBackground width="1440" height="797" viewBox="0 0 1440 797" fill="none">
      <path d="M1440 575C1025.48 572.005 817.486 0 0 0V796.5H1440V575Z" fill="#141823"/>
    </StyledWaveBackground>
  </StyledSection>
);

export default HeroSection;