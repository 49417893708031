import React from 'react';
import Rating from '../Rating/Rating';  // Assuming you have a Rating component
import {
  StyledCard,
  StyledReviewerName,
  StyledRating,
  StyledReviewDate,
  StyledReviewComment,
} from "./StyledReviewCard";

const ReviewCard = ({ review }) => {
  return (
    <StyledCard>
      <StyledReviewerName>{review.name}</StyledReviewerName>
      <StyledRating>
        <Rating value={review.rating} />
      </StyledRating>
      <StyledReviewDate>{new Date(review.createdAt).toLocaleDateString()}</StyledReviewDate>
      <StyledReviewComment>{review.comment}</StyledReviewComment>
    </StyledCard>
  );
};

export default ReviewCard;