import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

import ProductHeader from "../../components/ProductHeader/ProductHeader";
import ShopObjectCardsContainer from "../../components/ShopObjectCardsContainer/ShopObjectCardsContainer";
import ShopObjectCard from "../../components/ShopObjectCard/ShopObjectCard";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import Paginate from "../../components/Paginater/Paginate";
import Message from "../../components/Message/Message";
import { useProducts } from '../../context/Context';
import { 
  StyledSection, 
  StyledSearchBar, 
  StyledSearchInput, 
  StyledSearchButton,
  StyledHeaderContainer
} from "./StyledShop";

const ShopPage = () => {
  const { keyword: urlKeyword = '', pageNumber = 1 } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { products, loading, error, page, pages, fetchProducts, itemsPerPage, setItemsPerPage } = useProducts();
  const [localItemsPerPage, setLocalItemsPerPage] = useState(itemsPerPage);
  const [searchKeyword, setSearchKeyword] = useState(urlKeyword);

  useEffect(() => {
    fetchProducts(urlKeyword, pageNumber, localItemsPerPage);
  }, [fetchProducts, urlKeyword, pageNumber, localItemsPerPage, location.pathname]);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setLocalItemsPerPage(newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKeyword.trim()) {
      navigate(`/search/${searchKeyword.trim()}`);
    } else {
      navigate('/shop');
      fetchProducts('', 1, localItemsPerPage);  // Call API with no keyword
    }
  };

  const title = urlKeyword ? `Results : "${urlKeyword}"` : "All Products";

  const renderProducts = () => {
    if (products.length === 0) {
      return <p>No products found.</p>;
    }
    
    return products.map((product) => (
      <ShopObjectCard
        key={product._id}
        id={product._id}
        img={product.image}
        brand={product.brand}
        name={product.name}
        part={product.partNum}
        price={product.price}
        to={`/product/${product._id}`}
        countInStock={product.countInStock}
      />
    ));
  };

  if (loading) {
    return (
      <StyledSection>
        <ShopObjectCardsContainer>
          <LoadingBox />
        </ShopObjectCardsContainer>
      </StyledSection>
    );
  }

  if (error) {
    return (
      <StyledSection>
        <ShopObjectCardsContainer>
          <Message variant="danger">{error}</Message>
        </ShopObjectCardsContainer>
      </StyledSection>
    );
  }

  return (
    <>
      <StyledHeaderContainer>
        <ProductHeader title={title} />
        <StyledSearchBar onSubmit={handleSearch}>
          <StyledSearchInput
            type="text"
            name="q"
            onChange={(e) => setSearchKeyword(e.target.value)}
            placeholder="Search Products..."
            value={searchKeyword}
          />
          <StyledSearchButton type="submit">
            <FaSearch />
            {searchKeyword.trim() ? "Search" : "Show All"}
          </StyledSearchButton>
        </StyledSearchBar>
      </StyledHeaderContainer>
      <StyledSection>
        <ShopObjectCardsContainer>
          {renderProducts()}
        </ShopObjectCardsContainer>
        <Paginate
          pages={pages}
          page={page}
          keyword={urlKeyword ? urlKeyword : ""}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </StyledSection>
    </>
  );
};

export default ShopPage;