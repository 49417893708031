import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useCart, useOrder, useUser } from '../../context/Context';
import axios from 'axios';
import {
    StyledContainer,
    StyledForm,
    StyledLabel,
    StyledInput,
    StyledButton,
    StyledSummary,
    StyledStep,
    StyledPayPalContainer,
    StyledOrderItem,
    GoogleAutoCompleteStyle,
    StyledAddressInputContainer,
    StyledAddressInput
  } from './StyledCheckOut'


const CheckoutPage = () => {
  const [step, setStep] = useState(1);
  const [shippingAddress, setShippingAddress] = useState({
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [paypalClientId, setPaypalClientId] = useState('');
  const [addressInput, setAddressInput] = useState('');
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);

  const navigate = useNavigate();
  const { cartItems, saveShippingAddress, clearCart } = useCart();
  const { createOrder, payOrder } = useOrder();
  const { user } = useUser();
  const [orderSummary, setOrderSummary] = useState({
    itemsPrice: 0,
    shippingPrice: 0,
    totalPrice: 0,
  });
  
  const getAuthConfig = useCallback(() => {
    if (!user || !user.token) {
      console.error('No user token found');
      return null;
    }
    return {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    };
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }

    const fetchPaypalClientId = async () => {
      try {
        const { data: clientId } = await axios.get("/api/config/paypal");
        setPaypalClientId(clientId);
      } catch (error) {
        console.error('Error fetching PayPal client ID:', error);
      }
    };

    if (step === 2) {
      fetchPaypalClientId();
    }
  }, [step, user, navigate]);

  useEffect(() => {
    if (cartItems.length > 0) {
      const itemsPrice = cartItems.reduce((acc, item) => acc + item.price * item.qty, 0);
      const shippingPrice = calculateShipping(itemsPrice);
      const totalPrice = itemsPrice + shippingPrice;

      setOrderSummary({
        itemsPrice: parseFloat(itemsPrice.toFixed(2)),
        shippingPrice: parseFloat(shippingPrice.toFixed(2)),
        totalPrice: parseFloat(totalPrice.toFixed(2)),
      });
    }
  }, [cartItems]);

  const calculateShipping = (itemsPrice) => {
    // Basic shipping calculation - can be replaced with more complex logic
    if (itemsPrice > 50) return 0; // Free shipping over $50
    return 0; // Flat rate of $10 for orders under $100
  };

  const submitShippingAddress = (e) => {
    e.preventDefault();
    saveShippingAddress(shippingAddress);
    setStep(2);
    console.log('Shipping Address saved:', shippingAddress);
  };

  const createPayPalOrder = (data, actions) => {
    console.log('Creating PayPal order for total:', orderSummary.totalPrice);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: orderSummary.totalPrice.toFixed(2),
            currency_code: "AUD"
          },
        },
      ],

      application_context: {
        shipping_preference: 'NO_SHIPPING'
      },

      payer: {
        address: {
          country_code: 'AU'
        }
      }
    });
  };

  const onApprove = async (data, actions) => {
    try {
      console.log('PayPal payment approved. Capturing order...');
      const details = await actions.order.capture();
      console.log('PayPal order captured:', details);
  
      const paymentResult = {
        id: details.id,
        status: details.status,
        update_time: details.update_time,
        email_address: details.payer.email_address,
      };
      
      const order = {
        orderItems: cartItems,
        shippingAddress: shippingAddress,
        paymentMethod: 'PayPal',
        itemsPrice: orderSummary.itemsPrice,
        shippingPrice: orderSummary.shippingPrice,
        totalPrice: orderSummary.totalPrice,
      };
      
      console.log('Creating order with data:', JSON.stringify(order, null, 2));
      const createdOrder = await createOrder(order);
      console.log('Order created:', createdOrder);
  
      if (createdOrder && createdOrder._id) {
        console.log('Marking order as paid...');
        const updatedOrder = await payOrder(createdOrder._id, paymentResult);
        console.log('Order marked as paid:', updatedOrder);
        
        if (updatedOrder && updatedOrder.isPaid) {
          console.log('Sending order confirmation email...');
          try {
            await sendOrderConfirmationEmail(updatedOrder);
            console.log('Order confirmation email sent successfully');
          } catch (emailError) {
            console.error('Failed to send order confirmation email:', emailError);
            // Note: We're not throwing an error here to avoid disrupting the order process
          }
  
          console.log('Clearing cart and navigating to order page...');
          clearCart();
          navigate(`/order/${createdOrder._id}`);
        } else {
          throw new Error('Order not marked as paid after update');
        }
      } else {
        throw new Error('Created order is invalid');
      }
    } catch (error) {
      console.error('Error processing order:', error);
      console.error('Error details:', error.response?.data);
      alert('There was an error processing your order. Please try again or contact support.');
    }
  };
  
  const sendOrderConfirmationEmail = async (order) => {
    try {
      const config = getAuthConfig();
      if (!config) {
        console.error('Failed to get auth config');
        return; // Exit if we can't get the auth config
      }

      await axios.post('/api/email/send-order-confirmation', {
        order,
        user
      }, config);

      console.log('Order confirmation email sent successfully');
    } catch (error) {
      console.error('Error sending confirmation email:', error);
      // You might want to add some user feedback here, but don't throw the error
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCkCXeaJB3qGMtjfj0l5LbNwdSaoLrYFpM&libraries=places`;
    script.async = true;
    script.onload = () => setGoogleApiLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (googleApiLoaded && window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('address-input'),
        { types: ['address'] }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        console.log('Selected place:', place);
        
        const streetNumber = place.address_components.find(c => c.types.includes('street_number'))?.long_name || '';
        const route = place.address_components.find(c => c.types.includes('route'))?.long_name || '';
        
        const newShippingAddress = {
          address: `${streetNumber} ${route}`.trim(),
          city: place.address_components.find(c => c.types.includes('locality'))?.long_name || '',
          state: place.address_components.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',
          postalCode: place.address_components.find(c => c.types.includes('postal_code'))?.long_name || '',
          country: place.address_components.find(c => c.types.includes('country'))?.long_name || '',
        };
        
        setShippingAddress(newShippingAddress);
        setAddressInput(newShippingAddress.address);
        console.log('Updated shipping address:', newShippingAddress);
      });
    }
  }, [googleApiLoaded]);

  return (
    <StyledContainer>
      <GoogleAutoCompleteStyle />
      {step === 1 && (
        <StyledStep>
          <h2>Shipping Address</h2>
          <StyledForm onSubmit={submitShippingAddress}>
            <StyledLabel>
              Address:
              <StyledAddressInputContainer>
              <StyledAddressInput
                id="address-input"
                type="text"
                required
                value={addressInput}
                onChange={(e) => {
                  setAddressInput(e.target.value);
                  setShippingAddress({...shippingAddress, address: e.target.value});
                }}
              />
              </StyledAddressInputContainer>
            </StyledLabel>
            <StyledLabel>
              City:
              <StyledInput
                type="text"
                required
                value={shippingAddress.city}
                onChange={(e) => setShippingAddress({...shippingAddress, city: e.target.value})}
              />
            </StyledLabel>
            <StyledLabel>
              State:
              <StyledInput
                type="text"
                required
                value={shippingAddress.state}
                onChange={(e) => setShippingAddress({...shippingAddress, state: e.target.value})}
              />
            </StyledLabel>
            <StyledLabel>
              Postal Code:
              <StyledInput
                type="text"
                required
                value={shippingAddress.postalCode}
                onChange={(e) => setShippingAddress({...shippingAddress, postalCode: e.target.value})}
              />
            </StyledLabel>
            <StyledLabel>
              Country:
              <StyledInput
                type="text"
                required
                value={shippingAddress.country}
                onChange={(e) => setShippingAddress({...shippingAddress, country: e.target.value})}
              />
            </StyledLabel>
            <StyledButton type="submit">Continue to Payment</StyledButton>
          </StyledForm>
        </StyledStep>
      )}

{step === 2 && (
        <StyledStep>
          <h2>Order Summary</h2>
          <StyledSummary>
            <h3>Shipping Address:</h3>
            <p>{shippingAddress.address}, {shippingAddress.city}, {shippingAddress.state} {shippingAddress.postalCode}, {shippingAddress.country}</p>
            <h3>Order Items:</h3>
            {cartItems.length > 0 ? (
              cartItems.map(item => (
                <StyledOrderItem key={item.product}>
                  <span>{item.name}</span>
                  <span>Quantity: {item.qty}</span>
                  <span>${(item.price * item.qty).toFixed(2)}</span>
                </StyledOrderItem>
              ))
            ) : (
              <p>No items in cart</p>
            )}
            <h3>Order Summary:</h3>
            <p>Items: ${orderSummary.itemsPrice.toFixed(2)}</p>
            <p>Shipping: ${orderSummary.shippingPrice.toFixed(2)}</p>
            <h3>Total: ${orderSummary.totalPrice.toFixed(2)}</h3>
          </StyledSummary>
          <StyledPayPalContainer>
            {paypalClientId ? (
              <PayPalScriptProvider options={{ "client-id": paypalClientId, currency: "AUD" }}>
                <PayPalButtons 
                  createOrder={createPayPalOrder}
                  onApprove={onApprove}
                />
              </PayPalScriptProvider>
            ) : (
              <p>Loading PayPal...</p>
            )}
          </StyledPayPalContainer>
        </StyledStep>
      )}
    </StyledContainer>
  );
};

export default CheckoutPage;