import styled from "styled-components";
import { Button } from "../Button/Button";

export const StyledCard = styled.div`
  background-color: #141823;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  align-items: center;
  width: 250px;
  height: 445px; // Increased height to accommodate extra line
  justify-content: space-between;

  @media (max-width: 400px) {
    width: 300px;
    height: 420px;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledCelestialObject = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 0.5em;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 400px) {
    width: 80%;
    padding-top: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledBrand = styled.p`
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
  margin: 0;
  font-size: 0.7em;
  padding: 0.5em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 400px) {
    font-size: 0.8em;
  }
`;

export const StyledDescription = styled.p`
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin: 0;
  font-size: 0.9em;
  font-weight: 600;
  height: 3.6em; // Increased height for 3 lines
  display: -webkit-box;
  -webkit-line-clamp: 3; // Changed to 3 lines
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 400px) {
    font-size: 1em;
    width: 80%;
  }
`;

export const StyledPart = styled.p`
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
  margin: 0;
  font-size: 0.9em;
  opacity: 0.6;
  padding: 0.5em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 400px) {
    font-size: 1em;
  }
`;

export const StyledPrice = styled.p`
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
  margin: 0.5em 0;
  font-size: 1.2em;

  @media (max-width: 400px) {
    font-size: 1.2em;
    margin: 1em 0;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 0.5em;
  font-size: 0.9em;

  @media (max-width: 400px) {
    width: 80%;
    font-size: 1em;
    padding: 0.7em 1em;
  }
`;

export const StyledOutOfStockButton = styled.button`
  width: 100%;
  margin-top: 0.5em;
  font-size: 0.9em;
  background-color: #6c757d;
  color: #ffffff;
  padding: 0.5em 1em;
  border: none;
  border-radius: 4px;
  opacity: 0.65;

  @media (max-width: 400px) {
    width: 80%;
    font-size: 1em;
    padding: 0.7em 1em;
  }
`;