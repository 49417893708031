import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/Context';
import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledSubmitButton,
  StyledLink,
  StyledButtonContainer,
  PageTitle,
  StyledloginContainer
} from "./StyledProfile";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Current user:', user);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Attempting login...');
    await login(email, password);
  };

  useEffect(() => {
    if (user && user.token) {
      console.log('User logged in, redirecting to profile');
      navigate('/profile');
    }
  }, [user, navigate]);

  return (
    <StyledloginContainer>
      <StyledForm onSubmit={handleSubmit}>
        <PageTitle>Login</PageTitle>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <StyledLabel>
          Email:
          <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </StyledLabel>
        <StyledLabel>
          Password:
          <StyledInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Enter your password"
          />
        </StyledLabel>
        <StyledButtonContainer>
          <StyledSubmitButton type="submit">Login</StyledSubmitButton>
          <StyledLink to="/signup">Sign Up</StyledLink>
        </StyledButtonContainer>
      </StyledForm>
    </StyledloginContainer>
  );
};

export default LoginPage;