import styled from "styled-components";

export const NavbarContainer = styled.nav`
  padding: 0.5rem 1rem;
`;

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
`;

export const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    width: 30%;
    min-width: 200px;
    background-color: #1e2330;
    padding: 1rem;
    border-radius: 10px 0 10px 10px;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
`;

export const NavLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;

  &:hover {
    color: #96bcb7;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

export const CartButton = styled.a`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
`;

export const CartCount = styled.span`
  background-color: #4a90e2;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
`;

export const BurgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: block;
  }
`;