import React from "react";
import Rating from "../../components/Rating/Rating";
import { 
  StyledInformationContainer, 
  StyledHeading, 
  StyledQuantityInput,
  StyledPrice,
  StyledDescription,
  StyledAvailability,
  StyledQtyContainer,
  StyledRatingContainer,
  StyledBottomSection,
  StyledOutOfStockButton,
  StyledPart,
  StyledBrand
} from "./StyledProductInformation";
import { Button } from "../Button/Button";

const ProductInformation = ({ className, data, qty, setQty, addToCartHandler }) => {
  const handleQtyChange = (e) => {
    const newQty = parseInt(e.target.value);
    if (newQty > 0 && newQty <= data.countInStock) {
      setQty(newQty);
    }
  };

  return (
    <StyledInformationContainer className={className}>
      <StyledHeading>{data.name}</StyledHeading>
      <StyledBrand>Brand : {data.brand}</StyledBrand>
      <StyledPart>Part : {data.partNum}</StyledPart>
      <StyledRatingContainer>
        <Rating 
          value={data.rating} 
          text={`${data.numReviews} ${data.numReviews === 1 ? 'review' : 'reviews'}`}
        />
      </StyledRatingContainer>
      
      <StyledPrice>${parseFloat(data.price).toFixed(2)}</StyledPrice>
      
      <StyledDescription>{data.description}</StyledDescription>
      
      <StyledBottomSection>
        <StyledAvailability>
          <span>Available: {data.countInStock}</span>
          <span style={{ color: data.countInStock > 0 ? "green" : "red" }}>
            {data.countInStock > 0 ? "In Stock" : "Out Of Stock"}
          </span>
        </StyledAvailability>
        
        {data.countInStock > 0 && (
          <>
            <StyledQtyContainer>
              <label>Qty:</label>
              <StyledQuantityInput
                type="number"
                min="1"
                max={data.countInStock}
                value={qty}
                onChange={handleQtyChange}
              />
            </StyledQtyContainer>

            <Button 
              onClick={addToCartHandler}
              disabled={data.countInStock === 0}
            >
              Add To Cart
            </Button>
          </>
        )}
        
        
      </StyledBottomSection>
    </StyledInformationContainer>
  );
};

export default ProductInformation;