import React from 'react';
import { ProductProvider, UserProvider,OrderProvider, CartProvider } from './context/Context';
// Import other providers as needed

export const AppProvider = ({ children }) => {
  return (
    <UserProvider>
      <ProductProvider>
        <OrderProvider>
          <CartProvider>
            {/* Add other providers here */}
            {children}
          </CartProvider>
        </OrderProvider>
      </ProductProvider>
    </UserProvider>
  );
};