import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
`;

export const PageButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const PageButton = styled(Link)`
  background-color: #141823;
  color: white;
  border: 1px solid #2c3e50;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #2c3e50;
  }

  &.active {
    background-color: #3498db;
    border-color: #3498db;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Ellipsis = styled.span`
  margin: 0 0.5rem;
`;

export const ItemsPerPageSelect = styled.select`
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #2c3e50;
  background-color: #141823;
  color: white;
  margin-top: 1rem;
`;