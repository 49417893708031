import React from 'react';
import styled from 'styled-components';

const StarContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Star = styled.span`
  color: ${props => props.filled ? '#f8e825' : '#e4e5e9'};
  font-size: 1.2em;
  margin-right: 2px;
`;

const ReviewCount = styled.span`
  margin-left: 5px;
  font-size: 0.9em;
  color: #888;
`;

const Rating = ({ value, text, color }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const number = index + 0.5;
    return (
      <Star key={index} filled={value >= index + 1}>
        <i className={value >= number ? 'fas fa-star' : value >= index + 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
      </Star>
    );
  });

  return (
    <StarContainer>
      {stars}
      {text && <ReviewCount>{text}</ReviewCount>}
    </StarContainer>
  );
};

export default Rating;