import styled from "styled-components";
import { FaSearch } from 'react-icons/fa';

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4.5rem;
  background-color: #1a1e2e;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 1rem;
  }
`;

export const StyledSection = styled.section`
  margin-block-end: 10em;
`

export const HeaderCatigotyP = styled.h5`
  font-weight: 800;
  font-size: 1.3rem;
  margin: 1.15em 0;
  padding-left: 5%;
  ${(props) => {
    if (props.smallHeader)
      return `
    font-size: 1rem;
    font-weight: 600;
    margin: 1.4em 0;`;
    
  }};
`;

export const CatigotyP = styled.p`
  color: #cccccc;
  margin: 0;
  padding-left: 5%;
  ${(props) => {
    if (props.bottom)
      return `
    color: #A7A7A7;
    font-size: 0.875rem;`;
    
  }}
`;

export const StyledSearchBar = styled.form`
  display: flex;
  width: 50%;
  max-width: 500px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const StyledSearchInput = styled.input`
  flex-grow: 1;
  padding: 0.5em 1em;
  font-size: 1rem;
  border: none;
  border-radius: 4px 0 0 4px;
  background-color: #2a2f3e;
  color: #ffffff;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.3);
  }

  &::placeholder {
    color: #6c757d;
  }
`;

export const StyledSearchButton = styled.button`
  padding: 0.5em 1em;
  font-size: 1rem;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #357abd;
  }

  svg {
    margin-right: 0.5em;
  }

  @media (max-width: 768px) {
    padding: 0.5em;
    
    span {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
`;