import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/Context';
import { useOrder } from '../../context/Context';
import {
    StyledForm,
    StyledLabel,
    StyledInput,
    StyledButton,
    StyledCancelButton,
    StyledLogoutButton,
    PageTitle,
    StyledInfo,
    StyledButtonContainer,
    StyledProfileContainer,
    StyledOrderHistory,
    StyledOrderTable,
    StyledTableButton,
    StyledStatus,
    StyledOrderFilters,
    StyledDateInput,
    StyledSearchContainer,
    StyledToSpan,
    StyledAdminButton
} from "./StyledProfile";

const UserProfilePage = () => {
  const { 
    user, 
    updateUserProfile, 
    error: userError, 
    loading: userLoading, 
    logout 
  } = useUser();
  
  const { 
    listMyOrders, 
    orders, 
    loading: orderLoading, 
    error: orderError 
  } = useOrder();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [tempName, setTempName] = useState('');
  const [tempEmail, setTempEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setTempName(user.name);
      setTempEmail(user.email);
      setIsLoading(false);
      listMyOrders();
    } else {
      const storedUser = localStorage.getItem('userInfo');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setName(parsedUser.name);
        setEmail(parsedUser.email);
        setTempName(parsedUser.name);
        setTempEmail(parsedUser.email);
        setIsLoading(false);
        listMyOrders();
      } else {
        setIsLoading(false);
      }
    }
  }, [user, listMyOrders]);

  useEffect(() => {
    if (orders) {
      let filtered = [...orders];

      if (startDate) {
        filtered = filtered.filter(order => new Date(order.createdAt) >= new Date(startDate));
      }
      if (endDate) {
        filtered = filtered.filter(order => new Date(order.createdAt) <= new Date(endDate));
      }
      if (searchTerm) {
        filtered = filtered.filter(order => 
          order._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          getOrderStatus(order).toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredOrders(filtered);
    }
  }, [orders, startDate, endDate, searchTerm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateUserProfile({ name: tempName, email: tempEmail, password });
    if (!userError) {
      setName(tempName);
      setEmail(tempEmail);
      setIsEditing(false);
      setPassword('');
    }
  };

  const handleCancel = () => {
    setTempName(name);
    setTempEmail(email);
    setPassword('');
    setIsEditing(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const startEditing = () => {
    setIsEditing(true);
    setTempName(name);
    setTempEmail(email);
  };

  const getOrderStatus = (order) => {
    if (order.isDelivered) return 'Shipped';
    if (order.isPaid) return 'Paid';
    return 'Pending';
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Shipped': return '#28a745'; // green
      case 'Paid': return '#FF6347'; // yellow
      default: return '#dc3545'; // red
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user && !localStorage.getItem('userInfo')) {
    return <Navigate to="/login" replace />;
  }

  return (
    <StyledProfileContainer>
      <StyledForm onSubmit={handleSubmit}>
        <PageTitle>User Profile</PageTitle>
        {userError && <p style={{ color: 'red' }}>{userError}</p>}
        <StyledLabel>
            Name:
            {isEditing ? (
            <StyledInput
                type="text"
                value={tempName}
                onChange={(e) => setTempName(e.target.value)}
                required
            />
            ) : (
            <StyledInfo>{name}</StyledInfo>
            )}
        </StyledLabel>
        <StyledLabel>
            Email:
            {isEditing ? (
                <StyledInput
                    type="email"
                    value={tempEmail}
                    onChange={(e) => setTempEmail(e.target.value)}
                    required
                />
            ) : (
                <StyledInfo>{email}</StyledInfo>
            )}
        </StyledLabel>
        {isEditing && (
            <StyledLabel>
            New Password:
            <StyledInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Leave blank to keep current password"
            />
            </StyledLabel>
        )}
        <StyledButtonContainer>
          {isEditing ? (
            <>
              <StyledButton type="submit" disabled={userLoading}>
                {userLoading ? 'Updating...' : 'Update'}
              </StyledButton>
              <StyledCancelButton type="button" onClick={handleCancel}>
                Cancel
              </StyledCancelButton>
            </>
          ) : (
            <StyledButton type="button" onClick={startEditing}>
              Edit Profile
            </StyledButton>
          )}
          <StyledLogoutButton type="button" onClick={handleLogout}>
            Logout
          </StyledLogoutButton>
          {user && user.isAdmin && !isEditing && (
            <StyledAdminButton onClick={() => navigate('/admin')}>
              Admin
            </StyledAdminButton>
          )}
        </StyledButtonContainer>
      </StyledForm>
      
      
      <StyledOrderHistory>
        <h2>Order History</h2>
        <StyledOrderFilters>
          <StyledSearchContainer>
            <StyledInput
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search orders..."
            />
          </StyledSearchContainer>
          <StyledDateInput
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
          />
          <StyledToSpan>TO</StyledToSpan>
          <StyledDateInput
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
          />
        </StyledOrderFilters>
        {orderError && <p style={{ color: 'red' }}>{orderError}</p>}
        {orderLoading ? (
          <p>Loading orders...</p>
        ) : filteredOrders.length === 0 ? (
          <p>No orders found.</p>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <StyledOrderTable>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DATE</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order) => {
                  const status = getOrderStatus(order);
                  return (
                    <tr key={order._id}>
                      <td>{order._id.substring(order._id.length - 6)}</td>
                      <td>{formatDate(order.createdAt)}</td>
                      <td>
                        <StyledStatus color={getStatusColor(status)}>
                          {status}
                        </StyledStatus>
                      </td>
                      <td>
                        <StyledTableButton onClick={() => navigate(`/order/${order._id}`)}>
                          Details
                        </StyledTableButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </StyledOrderTable>
          </div>
        )}
      </StyledOrderHistory>
    </StyledProfileContainer>
  );
};

export default UserProfilePage;