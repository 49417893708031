import { Technology, Educational, Development } from "../../constants";
import { Link } from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import CelestialObjectCardsContainer from "../../components/CelestialObjectCardsContainer/CelestialObjectCardsContainer";
import CelestialObjectCard from "../../components/CelestialObjectCard/CelestialObjectCard";

import { StyledSection, HeaderCatigotyP, CatigotyP } from "./StyledPlanets";

const Planets = () => (
  <>
    <PageHeader title="Services"/>
    <StyledSection>
      <HeaderCatigotyP>Technology Assessment and Solutions</HeaderCatigotyP>
      <CatigotyP>Unlock the potential of technology in your organization with thorough assessments and tailored solutions from SlashTech.</CatigotyP>
      <CelestialObjectCardsContainer>
        
        {Development.map((planet) => (
          <Link to={`/services/${planet.id}`} style={{ textDecoration: 'none' }}>
            <CelestialObjectCard
              key={planet.id}
              id={planet.id}
              color={planet.color}
              name={planet.name}
              description={planet.description}
              to={`/services/${planet.id}`}
            />
          </Link>
        ))}
      </CelestialObjectCardsContainer>

      <HeaderCatigotyP>Educational Consultation and Innovation</HeaderCatigotyP>
      <CatigotyP>Slash Tech aims to empower individuals with tech skills, enabling them to innovate across various fields, from agriculture to industry. The goal is to foster positive global change through widespread technological empowerment.</CatigotyP>

      <CelestialObjectCardsContainer>
        {Educational.map((planet) => (
          <Link to={`/services/${planet.id}`} style={{ textDecoration: 'none' }}>
            <CelestialObjectCard
              key={planet.id}
              id={planet.id}
              color={planet.color}
              name={planet.name}
              description={planet.description}
              to={`/services/${planet.id}`}
            />
          </Link>
        ))}
      </CelestialObjectCardsContainer>

      <HeaderCatigotyP>Commercial and Educational Support</HeaderCatigotyP>
      <CatigotyP>From commercial tech solutions to educational initiatives, SlashTech provides comprehensive support to meet diverse requirements and objectives.</CatigotyP>

      <CelestialObjectCardsContainer>
        {Technology.map((planet) => (
          <Link to={`/services/${planet.id}`} style={{ textDecoration: 'none' }}>
            <CelestialObjectCard
              key={planet.id}
              id={planet.id}
              color={planet.color}
              name={planet.name}
              description={planet.description}
              to={`/services/${planet.id}`}
            />
          </Link>
        ))}
      </CelestialObjectCardsContainer>
    </StyledSection>
  </>
);

export default Planets;