import styled from "styled-components";

export const StyledInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: #1e2330;
  border-radius: 8px;
  height: 100%;
`;

export const StyledHeading = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
`;

export const StyledPart = styled.h1`
  font-size: 1.4rem;
  opacity: 0.4;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
`;
export const StyledBrand = styled.h1`
  font-size: 1.4rem;
  opacity: 0.4;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
`;

export const StyledRatingContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyledPrice = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4caf50;
`;

export const StyledDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

export const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledAvailability = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
`;

export const StyledQtyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledQuantityInput = styled.input`
  width: 60px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2f3e;
  color: #fff;
`;

export const StyledButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

export const StyledOutOfStockButton = styled(StyledButton)`
  background-color: #6c757d;
  cursor: not-allowed;

  &:hover {
    background-color: #6c757d;
  }
`;

// You can keep the existing Button component or style it here if needed