import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Button } from "../../components/Button/Button";
import ProductInformation from "../../components/ProductInformation/ProductInformation";
import LoadingBox from "../../components/LoadingBox/LoadingBox";

export const StyledPageHeaderLoadingBox = styled(LoadingBox)`
  height: 5rem;
`;

export const StyledCelestialObjectLoadingBox = styled(LoadingBox)`
  aspect-ratio: 1;
  border-radius: 50%;

  @media (min-width: 55em) {
    max-width: 40%;
  }
`;

export const StyledInformationLoadingBox = styled(LoadingBox)`
  height: 30em;

  @media (min-width: 55em) {
    max-width: 50%;
  }
`;

export const StyledCelestialObjectSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin-block: 2em;

  @media (min-width: 55em) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledCelestialObject = styled.div`
  @media (min-width: 55em) {
    width: 50%;
  }
`;

export const StyledMainImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  margin-bottom: 1rem;
`;

export const StyledCarousel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
`;

export const StyledThumbnail = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.6};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const StyledCelestialObjectInformation = styled(ProductInformation)`
  @media (min-width: 55em) {
    width: 45%;
  }
`;

export const StyledMoonsSection = styled.section`
  margin-block: 2em 10em;
  
  h2 {
    font-size: 2rem;
    margin-block: 4em 1em;
    text-align: center;
  }

  @media (min-width: 55em) {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 0.5em;
  font-size: 0.9em;

  @media (max-width: 400px) {
    width: 80%;
    font-size: 1em;
    padding: 0.7em 1em;
  }
`;

export const StyledReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const StyledShowMoreButton = styled(Button)`
  align-self: center;
  margin-top: 1rem;
  background-color: transparent;
  border: 1px solid #4a90e2;
  color: white;

  &:hover {
    background-color: #4a90e2;
  }
`;

export const StyledReviewFormContainer = styled.div`
  background-color: #1e2330;
  border-radius: 8px;
  padding: 1rem;  // Reduced from 1.5rem
  margin-top: 5rem;

  h2 {
    margin-top: 0;  // Remove top margin from the heading
    margin-bottom: 1rem;  // Add some space below the heading
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledLabel = styled.label`
  font-size: 1rem;
  color: #ffffff;
`;

export const StyledTextarea = styled.textarea`
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #141823;
  color: #ffffff;
  border: 1px solid #2a2f3e;
  resize: vertical;
`;

export const StyledLink = styled(Link)`
  color: #4a90e2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledSuggestionsTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const StyledSuggestionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;