import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import GlobalStyles from "./GlobalStyles";

import Layout from "./components/Layout/Layout";
import { Header } from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import NotFoundPage from "./pages/NotFound/NotFound";
import LandingPage from "./pages/Landing/Landing"
import BodyPage from "./pages/Body/Body";
import ProductPage from "./pages/Product/Product";
import PlanetsPage from "./pages/Planets/Planets";
import ShopPage from "./pages/Shop/Shop";
import CartPage from "./pages/Shop/Cart";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import LoginPage from './pages/Profile/Login';
import SignupPage from './pages/Profile/Signup';
import UserProfilePage from './pages/Profile/UserProfile'
import CheckoutPage from './pages/Shop/CheckOut';
import OrderPage from './pages/Profile/Order';
import AdminDashboard from './pages/Admin/Admin';
import ProductManagement from './pages/Admin/AdminProduct';
import OrderManagement from './pages/Admin/AdminOrders';
import UsersManagement from './pages/Admin/AdminUsers';

import { AppProvider } from './appProvider';

function App() {
  const location = useLocation();

  useEffect(() => { window.scrollTo(0, 0); }, [location])

  return (
    <>
      <GlobalStyles />
      <Layout>
        <AppProvider>
          <Header />
            <Routes>

              {/* main web site */}

              <Route path="*" element={<NotFoundPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />

              {/* servises */}
              <Route path="/services" element={<PlanetsPage />} />
              <Route path="/services/:id" element={<BodyPage />} />

              {/* shop */}
              
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/shop/:keyword/:pageNumber" element={<ShopPage />} />
              <Route path='/search/:keyword/page/:pageNumber' element={<ShopPage />} />
              <Route path='/search/:keyword' element={<ShopPage />} />
              <Route path='/page/:pageNumber' element={<ShopPage />} />
              <Route path='/product/:id' element={<ProductPage />} />
              <Route path='/cart' element={<CartPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />

              {/* profile */}

              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/order/:id" element={<OrderPage />} />
              
              {/* portal */}
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/admin/products" element={<ProductManagement />} />
              <Route path="/admin/products/search/:keyword/:pageNumber" element={<ProductManagement />} />
              <Route path="/admin/products/:pageNumber" element={<ProductManagement />} />
              <Route path="/admin/products/search/:keyword" element={<ProductManagement />} />
              <Route path="/admin/orders" element={<OrderManagement />} />
              <Route path="/admin/users" element={<UsersManagement />} />
              {/* <Route path='/admin/userlist' component={UserListScreen} />
              <Route path='/admin/user/:id/edit' component={UserEditScreen} />
              <Route path='/admin/orderlist' component={OrderListScreen} /> */}

            </Routes>
          <Footer />
        </AppProvider>
      </Layout>
    </>
  );
}

export default App;
