import { Development, Educational, Technology } from "../../constants";
import {
  Border,
  FooterA,
  FooterLink,
  FooterP,
  HeaderFooterP,
  StyledFooterColumn,
  StyledFooterRow,
  StyledFooterSection,
} from "./StyledFooter";

const Footer = () => {
  return (
    <StyledFooterSection>
      <StyledFooterColumn>
        <HeaderFooterP>S/ashTech</HeaderFooterP>
        <FooterP>
        At SlashTech, we're dedicated to transforming education through technology. Our commitment to innovation, collaboration, and accessibility drives everything we do. Whether you're a school looking to enhance your technology initiatives or an individual seeking cutting-edge solutions, SlashTech is here to support your journey. Get in touch with us today to explore how we can empower you to succeed in the digital age.
        </FooterP>
      </StyledFooterColumn>
      <StyledFooterRow margin>
        <StyledFooterRow>
          <StyledFooterRow linkContainer>
            <StyledFooterColumn>
              <HeaderFooterP smallHeader>Services</HeaderFooterP>
              {Development.map((item, index) => {
                return (
                  <FooterLink key={index} to={`/services/${item.id}`}>
                    {item.name}
                  </FooterLink>
                );
              })}

              {Educational.map((item, index) => {
                return (
                  <FooterLink key={index} to={`/services/${item.id}`}>
                    {item.name}
                  </FooterLink>
                );
              })}

              {Technology.map((item, index) => {
                return (
                  <FooterLink key={index} to={`/services/${item.id}`}>
                    {item.name}
                  </FooterLink>
                );
              })}
            </StyledFooterColumn>
            <StyledFooterColumn>
              <HeaderFooterP smallHeader>Information</HeaderFooterP>
              <FooterA href="#">How it works</FooterA>
              <FooterA href="#">Download app</FooterA>
              <FooterA href="#">Developer portal</FooterA>
            </StyledFooterColumn>
            <StyledFooterColumn>
              <HeaderFooterP smallHeader>About</HeaderFooterP>
              <FooterA href="#">News and press</FooterA>
              <FooterA href="#">FAQ</FooterA>
            </StyledFooterColumn>
          </StyledFooterRow>
        </StyledFooterRow>
      </StyledFooterRow>
      <Border />
      <StyledFooterRow bottomContainer>
        <FooterP bottom>
          Copyright 2023 S/ashT3ch, All rights reserved.
        </FooterP>
        <StyledFooterRow bottomDiv>
          <FooterP bottom>ABN | 82 107 510 154</FooterP>
          <FooterP bottom>Terms of Service</FooterP>
          <FooterP bottom>Privacy Policy</FooterP>
        </StyledFooterRow>
      </StyledFooterRow>
    </StyledFooterSection>
  );
};

export default Footer;