import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useOrder } from '../../context/Context';
import {
  StyledContainer,
  StyledOrderInfo,
  StyledOrderItems,
  StyledOrderSummary,
  StyledHeading,
  StyledItem,
  StyledButton
} from './StyledOrder';

const OrderPage = () => {
  const { id: orderId } = useParams();
  const { getOrderDetails } = useOrder();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const orderData = await getOrderDetails(orderId);
        setOrder(orderData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId, getOrderDetails]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!order) return <div>Order not found</div>;

  console.log(order.orderItems)

  return (
    <StyledContainer>
      <StyledHeading>Order: {order._id}</StyledHeading>
      <StyledOrderInfo>
        <h2>Shipping Info</h2>
        <p>
          <strong>Name: </strong> {order.user.name}
        </p>
        <p>
          <strong>Email: </strong> {order.user.email}
        </p>
        <p>
          <strong>Address: </strong>
          {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
          {order.shippingAddress.postalCode}, {order.shippingAddress.country}
        </p>
        {order.isDelivered ? (
          <div>Shipped on {order.deliveredAt}</div>
        ) : (
          <div>Not Shipped</div>
        )}
      </StyledOrderInfo>

      <StyledOrderInfo>
        <h2>Payment</h2>
    
        {order.isPaid ? (
          <div>Paid on {order.paidAt}</div>
        ) : (
          <div>Not Paid</div>
        )}
      </StyledOrderInfo>

      <StyledOrderItems>
        <h2>Order Items</h2>
        {order.orderItems.map((item) => (
          <StyledItem key={item._id}>
            <img src={process.env.REACT_APP_API_URL + item.image} alt={item.name} />
            <Link to={`/product/${item.product}`}>{item.name}</Link>
            <span>
              {item.qty} x ${item.price} = ${item.qty * item.price}
            </span>
          </StyledItem>
        ))}
      </StyledOrderItems>

      <StyledOrderSummary>
        <h2>Order Summary</h2>
        <div>
          <strong>Items:</strong> ${order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)}
        </div>
        <div>
          <strong>Shipping:</strong> ${order.shippingPrice}
        </div>
        <div>
          <strong>Total:</strong> ${order.totalPrice}
        </div>
      </StyledOrderSummary>

      {!order.isPaid && (
        <StyledButton>Pay Order</StyledButton>
      )}
    </StyledContainer>
  );
};

export default OrderPage;