import React from 'react';
import styled from 'styled-components';

const StarContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Star = styled.span`
  color: ${props => props.filled ? '#f8e825' : '#e4e5e9'};
  font-size: 1.2em;
  margin-right: 2px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #f8e825;
  }
`;

const StarRating = ({ rating, setRating }) => {
  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  return (
    <StarContainer>
      {[1, 2, 3, 4, 5].map((value) => {
        const number = value - 0.5;
        return (
          <Star 
            key={value} 
            filled={rating >= value}
            onClick={() => handleStarClick(value)}
          >
            <i 
              className={
                rating >= number
                  ? 'fas fa-star'
                  : rating >= value - 0.5
                  ? 'fas fa-star-half-alt'
                  : 'far fa-star'
              }
            ></i>
          </Star>
        );
      })}
    </StarContainer>
  );
};

export default StarRating;