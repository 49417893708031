import styled from "styled-components";

export const StyledSection = styled.section`
  margin-block-end: 10em;
`

export const HeaderCatigotyP = styled.h5`
  font-weight: 800;
  font-size: 1.3rem;
  margin: 1.15em 0;
  padding-left: 5%;
  ${(props) => {
    if (props.smallHeader)
      return `
    font-size: 1rem;
    font-weight: 600;
    margin: 1.4em 0;`;
    
  }};
`;

export const CatigotyP = styled.p`
  color: #cccccc;
  margin: 0;
  padding-left: 5%;
  ${(props) => {
    if (props.bottom)
      return `
    color: #A7A7A7;
    font-size: 0.875rem;`;
    
  }}
`;