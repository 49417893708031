import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/Context';
import {
  StyledCard,
  StyledCelestialObject,
  StyledDescription,
  StyledButton,
  StyledPrice,
  StyledOutOfStockButton,
  StyledBrand,
  StyledPart
} from "./StyledShopObjectCard";

const ShopObjectCard = ({
  id,
  className,
  img,
  brand,
  name,
  part,
  price,
  to,
  countInStock
}) => {
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const handleAddToCart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addToCart(id, 1);
  };

  const handleCardClick = () => {
    navigate(`/product/${id}`);
  };

  const inStock = countInStock > 0;

  // Get the first image URL if img is an array, otherwise use img directly
  const imageUrl = Array.isArray(img) ? img[0] : img;

  const fullImageUrl = `${process.env.REACT_APP_API_URL }${imageUrl}`;

  return (
    <StyledCard className={className} onClick={handleCardClick}>
      <StyledCelestialObject>
        <img src={fullImageUrl} alt={name} />
      </StyledCelestialObject>
      <StyledBrand title={brand}>{brand}</StyledBrand>
      <StyledDescription title={name}>{name}</StyledDescription>
      <StyledPart title={part}>- {part}</StyledPart>
      <StyledPrice>{"$" + price}</StyledPrice>
      {inStock ? (
        <StyledButton onClick={handleAddToCart}>Add to cart</StyledButton>
      ) : (
        <StyledOutOfStockButton disabled>Out of Stock</StyledOutOfStockButton>
      )}
    </StyledCard>
  );
};

export default ShopObjectCard;