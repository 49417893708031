import styled from "styled-components";

export const AboutH1 = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.2189999999999999em;
  margin-top: 1.90625em;
  padding-left: 5%;
`;

export const AboutH2 = styled.h1`
  font-size: 1.5rem;
  padding-left: 5%;
`;

export const AboutP = styled.p`
  font-size: 1rem;
  margin-bottom: 1.2189999999999999em;
  max-width: 75ch;
  padding-left: 5%;
`;

export const AboutSection = styled.section`
  margin-bottom: 10em;
  padding-left: 5%;
`;
