import styled from "styled-components";

export const StyledCelestialObjectCardsContainer = styled.div`
  max-width: 100%;
  padding: 5%;
  display: grid;
  gap: 1em;
  justify-content: center;
  
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
    padding: 1em;
    gap: 1.5em;
  }

  @media (min-width: 401px) and (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  @media (min-width: 601px) and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1201px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;