import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../../context/Context';
import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledSubmitButton,
  StyledLink,
  StyledButtonContainer,
  PageTitle,
  StyledloginContainer
} from "./StyledProfile";

const SignupPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { register, error } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await register(name, email, password);
    if (!error) {
      navigate('/profile');
    }
  };

  return (
    <StyledloginContainer>
        <StyledForm onSubmit={handleSubmit}>
        <PageTitle>Sign Up</PageTitle>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <StyledLabel>
            Name:
            <StyledInput
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Enter your name"
            />
        </StyledLabel>
        <StyledLabel>
            Email:
            <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
            />
        </StyledLabel>
        <StyledLabel>
            Password:
            <StyledInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Choose a password"
            />
        </StyledLabel>
        <StyledButtonContainer>
            <StyledSubmitButton type="submit">Sign Up</StyledSubmitButton>
            <StyledLink to="/login">Back to Login</StyledLink>
        </StyledButtonContainer>
        </StyledForm>
    </StyledloginContainer>
  );
};

export default SignupPage;