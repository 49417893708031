import wshop from "./imges/Workshop-modified.png"
import tech from "./imges/Technology-modified.png"
import grant from "./imges/Grants-modified.png"
import comm from "./imges/Comm-modified.png"
import dev from "./imges/Dev-modified.png"
import curr from "./imges/Curri-modified.png"
import ases from "./imges/Assesment-modified.png"
import con from "./imges/Consultations-modified.png"

export const endpoints = {
  bodies: 'https://api.le-systeme-solaire.net/rest/bodies'
}

export const colors = {
  mercure: '#FFBFAB',
  venus:   '#E7F6A7',
  terre:   '#1E84FD',
  mars:    '#FD531E',
  jupiter: '#FF9E44',
  saturne: '#F5D86D',
  uranus:  '#CBFFF3',
  neptune: '#2CD8FE',
  moon:    '#A7A7A7'
}

export const Development = [
  {
    id: 'SLT-001',  
    name: 'Assessment', 
    description: 'Comprehensive assessments to evaluate technology infrastructure and needs.', 
    info: ' SlashTech conducts thorough assessments of technology infrastructure and needs to help schools identify strengths, weaknesses, and opportunities for improvement. Our assessments cover areas such as hardware and software capabilities, network infrastructure, digital resources, and professional development needs. By gaining insights from these assessments, schools can make informed decisions and prioritise investments in technology.',
    color: ases,
    options: ['Technology Assessment', 'Network Evaluation', 'Digital Resources Evaluation', 'Data Privacy Assessment', 'Data Security Assessment', 'Monitoring and Evaluation', 'More ...']
  },

  {
    id: 'SLT-002', 
    name: 'Consultation', 
    description: 'Expert consultation services to provide guidance on technology-related initiatives.',
    info: "SlashTech offers expert consultation services to schools seeking guidance on technology-related initiatives. Whether it's developing a technology roadmap, evaluating technology solutions, or addressing specific challenges, our team of technology experts provides valuable insights and recommendations. We work collaboratively with schools to develop strategic plans and implement effective technology solutions that support their educational goals.",
    color: con,
    options: ['Roadmap Development', 'Tech Solution Evaluation', 'Implementation Support', 'Development Planning', 'Policy Reviews', 'More ...']
  },

  {
    id: 'SLT-003',    
    name: 'Commercial', 
    description: 'Customisable technology solutions for commercial and domestic applications.', 
    info: "In addition to educational projects, SlashTech offers commercial and domestic variations of our technology solutions. Whether it's for commercial applications, home automation, or personal projects, we provide customisable solutions to meet diverse needs. Our team works closely with clients to understand their requirements and develop tailored solutions that deliver results.",  
    color: comm,
    options: ['Commercial Tech solutions', 'Home Automation Systems', 'Digital Marketing Solutions', 'Custom Projects' ]
  },

  {
    id: 'SLT-004',   
    name: 'Grants', 
    description: 'Guidance and support in securing grants for technology and agriculture initiatives.', 
    info: 'We understand the importance of funding in implementing technology projects in schools. SlashTech provides expert assistance in researching available grants, writing compelling proposals, and navigating the grant application process. Our goal is to help schools access the resources they need to enhance their technology infrastructure and educational offerings, ultimately enriching the learning experience for students.', 
    color: grant,
    options: ['Grant Research', 'Proposal Writing', 'Application Assistance']
  },

]

export const Educational = [
  
  {
    id: 'SLT-011', 
    name: 'Workshops', 
    description: 'Engaging hands-on workshops covering various technology topics.',
    info: 'Our learning workshops are designed to inspire and educate students in the fields of programming, robotics, electronics, and more, all while incorporating principles of environmental sustainability. Led by experienced instructors, these workshops provide participants with practical skills and knowledge through interactive activities and projects. From beginner to advanced levels, our workshops cater to diverse interests and skill levels, fostering a love for technology and innovation while promoting environmental stewardship.', 
    color: wshop,
    options: ['Introduction to Coding', 'Environmental Monitoring', 'Advanced Arduino Projects', 'Web Development Course',  'Artificial Intelligence Basics', 'Game Dev Workshop', 'Smart Agriculture', 'IoT with Arduino', 'Robotics 101', 'More ...']
  },

  {
    id: 'SLT-012', 
    name: 'Development', 
    description: 'Training programs for educators to enhance technology integration skills.',
    info: 'SlashTech offers professional development programs designed to equip educators with the skills and knowledge needed to effectively integrate technology into their teaching practices. Our training sessions cover a wide range of topics, including instructional technology tools, digital literacy, and technology-enhanced learning strategies. By investing in professional development, schools can empower their teachers to leverage technology to enhance student learning outcomes.',
    color: dev,
    options: ['Coding', 'Micro controllers', 'Software', '3D Printing', 'Laser Cutting', 'Internet of Things', 'Robotics Kits', 'Smart e-Gardens', 'Virtual reality (VR)', 'Coding Clubs', 'More ...']
  },

  {
    id: 'SLT-013',   
    name: 'Technology', 
    description: 'Development for educators to enhance curriculum and technology integration.',
    info: "SlashTech offers comprehensive support for integrating technology into educational settings. Whether it's setting up maker spaces, coding clubs, or implementing STEM programs, we work closely with schools to develop customised solutions that align with their goals and objectives. Our expertise in technology integration ensures a smooth transition and maximises the benefits of technology for both educators and students.", 
    color: tech,
    options: ['Coding', 'Micro controllers', 'Software', '3D Printing', 'Laser Cutting', 'Internet of Things', 'Robotics Kits', 'Smart e-Gardens', 'Virtual reality (VR)', 'Coding Clubs', 'More ...']
  },

  {
    id: 'SLT-014', 
    name: 'Curriculum', 
    description: 'Seamless integration of technology into school curricula and extra curricular environments.', 
    info: 'SlashTech offers professional curriculum development services customised to meet the specific needs and goals of educational institutions. Our team works closely with educators to design engaging and effective technology curriculum that aligns with educational standards and promotes hands-on learning experiences. From computer science and coding to robotics and engineering, our curriculum solutions provide students with the skills and knowledge needed to succeed in today\'s digital world.',
    color: curr,
    options: ['Curriculum Development', 'Educational Syllabus Compliance', 'Student Assessment Tools', 'Alignment with Educational Standards', 'STEM Units and Programs', 'Hands-On Mind-On Learning Experience', 'Engaging Content', 'Teacher Training and Support', 'Customisation Options']
  },

]

export const Technology = [
  
  {
    id: 'SLT-021', 
    name: '3D Printing', 
    description: 'Cutting-edge 3D printing services for projects and prototypes.', 
    info: "SlashTech offers advanced 3D printing services for clients looking to bring their ideas to life. Whether you're a designer, engineer, or hobbyist, our 3D printers enable you to turn concepts into reality with precision and efficiency. From rapid prototyping to customised manufacturing, we provide comprehensive support throughout the entire process, ensuring high-quality results and innovative solutions.",
    color: curr,
    options: ['Rapid Prototyping', 'Customised Manufacturing', 'Quality Assurance', 'Material Selection', 'Technical Expertise', 'Collaborative Design']
  },
  
  {
    id: 'SLT-022', 
    name: 'Laser Cutting',  
    description: 'Precision laser cutting services for creative projects and production.', 
    info: "SlashTech offers precision laser cutting services for clients seeking high-quality fabrication solutions. Whether you're an artist, entrepreneur, or DIY enthusiast, our laser cutting technology allows for intricate designs and precise cuts on a variety of materials. From signage and artwork to product prototypes and decorative items, we bring your visions to life with craftsmanship and expertise.",
    color: curr,
    options: ['Custom Design and Fabrication', 'Creative Projects', 'Prototyping Services', 'Material Versatility', 'Technical Support', 'Quality Craftsmanship']
  },
  
  {
    id: 'SLT-023', 
    name: 'IoT Installation',   
    description: 'Expert installation services for IoT and LoRa projects.', 
    info: "SlashTech offers expert installation services for clients looking to implement IoT (Internet of Things) and LoRa (Long-Range) projects in various applications. Whether you're in agriculture, smart cities, or industrial automation, our team provides comprehensive installation support to ensure seamless integration and optimal performance. With our expertise in IoT and LoRa technologies, we help you leverage connectivity for innovative solutions and operational efficiency.",
    color: curr,
    options: ['Customised Solutions', 'Hardware Installation', 'Network Configuration', 'Data Management', 'Training and Support', 'Maintenance and Upgrades']
  },
  
  {
    id: 'SLT-024', 
    name: 'Fabrication',   
    description: 'Professional engineering and fabrication services for diverse projects.', 
    info: "SlashTech offers professional engineering and fabrication services for clients seeking high-quality solutions. If you're in product development or general engineering our team provides comprehensive support throughout the entire engineering and fabrication process. From concept design and prototyping to final production, we deliver innovative solutions that meet your technical requirements.",
    color: curr,
    options: ['Concept Development', 'Prototyping', 'Fabrication', 'Quality Products', 'Project Management']
  }
  
]