import styled from "styled-components";
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  max-width: 400px;
  max-height: 530px;
  padding: 2em;
  background-color: #1a1e2e;
  border-radius: 8px;
`;

export const PageTitle = styled.h1`
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 0.5em;
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: #ffffff;
`;

const inputStyles = `
  width: 100%;
  padding: 0.5em;
  border: 1px solid #2a2f3e;
  border-radius: 4px;
  background-color: #1e2330;
  color: #ffffff;
  font-size: 1rem;
  height: 38px;
  box-sizing: border-box;

  &::placeholder {
    color: #6c757d;
  }
`;

export const StyledInput = styled.input`
  ${inputStyles}
`;



export const StyledInfo = styled.span`
  font-size: 1rem;
  color: #ffffff;
  padding: 0.75em;
  background-color: #2a2f3e;
  border-radius: 4px;
  display: block;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
  margin-top: 1em;
`;

export const StyledButton = styled.button`
  padding: 0.75em 1em;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

export const StyledAdminButton = styled(StyledButton)`
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
`;

export const StyledCancelButton = styled(StyledButton)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

export const StyledLogoutButton = styled(StyledButton)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

export const StyledSubmitButton = styled.button`
  padding: 0.75em 2em;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

export const StyledLink = styled(Link)`
  padding: 0.75em 2em;
  border: none;
  border-radius: 4px;
  background-color: #6c757d;
  color: #ffffff;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5a6268;
  }
`;

export const StyledProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2em auto;
  gap: 2em;
  max-width: 1200px;
  padding: 0 1em;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledloginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2em auto;
  gap: 2em;
  max-width: 1200px;
  padding: 0 1em;

  
  flex-direction: column;
  align-items: center;
  
`;

export const StyledOrderHistory = styled.div`
  flex: 1;
  width: 100%;
  background-color: #1a1e2e;
  border-radius: 8px;
  padding: 2em;
  overflow-x: auto;

  @media (max-width: 768px) {
    padding: 1em;
  }
`;

export const StyledOrderTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;

  th, td {
    text-align: left;
    padding: 0.5em;
    border-bottom: 1px solid #2a2f3e;
  }

  th {
    background-color: #2a2f3e;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;

    th, td {
      padding: 0.3em;
    }
  }
`;

export const StyledTableButton = styled(StyledButton)`
  padding: 0.5em 1em;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    padding: 0.3em 0.8em;
    font-size: 0.8rem;
  }
`;

export const StyledStatus = styled.span`
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  font-size: 0.9em;
  font-weight: bold;
  color: #fff;
  background-color: ${props => props.color || '#6c757d'};
`;

export const StyledOrderFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1em;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyledSelect = styled.select`
  padding: 0.5em;
  border: 1px solid #2a2f3e;
  border-radius: 4px;
  background-color: #1e2330;
  color: #ffffff;
  font-size: 1rem;
`;

export const StyledSearchContainer = styled.div`
  flex: 1;
  min-width: 200px;
`;

export const StyledDateInput = styled.input`
  padding: 0.5em;
  border: 1px solid #2a2f3e;
  border-radius: 4px;
  background-color: #1e2330;
  color: #ffffff;
  font-size: 1rem;

  &::placeholder {
    color: #6c757d;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledToSpan = styled.span`
  color: #ffffff;
  font-size: 1rem;
  padding: 0 0.5em;

  @media (max-width: 768px) {
    align-self: center;
    padding: 0.5em 0;
  }
`;