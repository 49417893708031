import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useProducts } from '../../context/Context';
import { useCart } from '../../context/Context';
import { useUser } from '../../context/Context';
import Message from "../../components/Message/Message";
import PageHeader from "../../components/PageHeader/PageHeader";
import StarRating from '../../components/StarRating/StarRating';
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import ShopObjectCard from "../../components/ShopObjectCard/ShopObjectCard";
import ShopObjectCardsContainer from "../../components/ShopObjectCardsContainer/ShopObjectCardsContainer";

import {
  StyledPageHeaderLoadingBox,
  StyledCelestialObjectLoadingBox,
  StyledCelestialObjectSection,
  StyledInformationLoadingBox,
  StyledCelestialObject,
  StyledCelestialObjectInformation,
  StyledMoonsSection,
  StyledButton,
  StyledCarousel,
  StyledThumbnail,
  StyledMainImage,
  StyledReviewsContainer,
  StyledShowMoreButton,
  StyledTextarea,
  StyledForm,
  StyledFormGroup,
  StyledLabel,
  StyledLink,
  StyledReviewFormContainer,
  StyledSuggestionsTitle,
} from "./StyledProduct";

const ProductPage = () => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [localError, setLocalError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [, setAddToCartSuccess] = useState(false);

  const { id: productId } = useParams();
  
  const { 
    listProductDetails, 
    createProductReview, 
    product, 
    loading, 
    error, 
    successProductReview,
    setSuccessProductReview,
    products,
    fetchProducts,
  } = useProducts();

  const { addToCart } = useCart();
  const { user } = useUser();

  const getFullImageUrl = useCallback((imageUrl) => {
    return `${process.env.REACT_APP_API_URL || ''}${imageUrl}`;
  }, []);

  const fetchProduct = useCallback(async () => {
    try {
      await listProductDetails(productId);
    } catch (err) {
      console.error("Error fetching product details:", err);
      setLocalError(err.message || "An error occurred while fetching product details");
    }
  }, [productId]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    if (products.length === 0) {
      fetchProducts();
    }
  }, [fetchProducts, products.length]);

  useEffect(() => {
    if (successProductReview) {
      alert('Review submitted!')
      setRating(0)
      setComment('')
      setSuccessProductReview(false)
    }
  }, [successProductReview, setSuccessProductReview])

  useEffect(() => {
    if (products.length > 0 && product) {
      const filteredProducts = products.filter(p => p._id !== product._id);
      const randomProducts = filteredProducts
        .sort(() => 0.5 - Math.random())
        .slice(0, 4);
      setSuggestedProducts(randomProducts);
    }
  }, [products, product]);

  const addToCartHandler = (e) => {
    e.preventDefault();
    addToCart(productId, qty);
    setAddToCartSuccess(true);
    setTimeout(() => setAddToCartSuccess(false), 3000); // Hide success message after 3 seconds
  };

  const submitHandler = (e) => {
    e.preventDefault();
    
    createProductReview(productId, {
      rating,
      comment,
    });
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  const toggleShowAllReviews = () => {
    setShowAllReviews(!showAllReviews);
  };

  const reviews = product?.reviews || [];
  const displayedReviews = showAllReviews ? reviews : reviews.slice(0, 3);

  if (loading) {
    return (
      <>
        <PageHeader>
          <StyledPageHeaderLoadingBox />
        </PageHeader>
        <StyledCelestialObjectSection>
          <StyledCelestialObjectLoadingBox />
          <StyledInformationLoadingBox />
        </StyledCelestialObjectSection>
      </>
    );
  }

  if (error || localError) {
    return <Message variant="danger">{error || localError}</Message>;
  }

  if (!product) {
    return <Message>Product not found</Message>;
  }

  const productImages = Array.isArray(product.image) ? product.image : [product.image];

  return (
    <>
      <PageHeader title={product.name} />
      <StyledCelestialObjectSection>
        <StyledCelestialObject>
          <StyledMainImage src={getFullImageUrl(productImages[currentImageIndex])} alt={product.name} />
          <StyledCarousel>
            {productImages.map((image, index) => (
              <StyledThumbnail
                key={index}
                src={getFullImageUrl(image)}
                alt={`${product.name} thumbnail ${index + 1}`}
                active={index === currentImageIndex}
                onClick={() => handleThumbnailClick(index)}
              />
            ))}
          </StyledCarousel>
        </StyledCelestialObject>
        <StyledCelestialObjectInformation 
          data={product}
          qty={qty}
          setQty={setQty}
          addToCartHandler={addToCartHandler}
        />
      </StyledCelestialObjectSection>

      <StyledMoonsSection>
        <StyledSuggestionsTitle>You might also like</StyledSuggestionsTitle>
        <ShopObjectCardsContainer>
          {suggestedProducts.map((suggestedProduct) => (
            <ShopObjectCard
              key={suggestedProduct._id}
              id={suggestedProduct._id}
              img={suggestedProduct.image}
              name={suggestedProduct.name}
              price={suggestedProduct.price}
              to={`/product/${suggestedProduct._id}`}
              countInStock={suggestedProduct.countInStock}
            />
          ))}
        </ShopObjectCardsContainer>

        <StyledReviewFormContainer>
          <h2>Write a Review</h2>
          {successProductReview && (
            <Message variant="success">
              Review submitted successfully
            </Message>
          )}
          {/* Replace true with your authentication check */}
          {user ? (
            <StyledForm onSubmit={submitHandler}>
              <StyledFormGroup>
                <StyledLabel>Rating</StyledLabel>
                <StarRating rating={rating} setRating={setRating} />
              </StyledFormGroup>
              <StyledFormGroup>
                <StyledLabel htmlFor="comment">Comment</StyledLabel>
                <StyledTextarea
                  id="comment"
                  rows="3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></StyledTextarea>
              </StyledFormGroup>
              <StyledButton type="submit" onClick={() => createProductReview(productId, {
                rating,
                comment,
              })}>
                Submit Review
              </StyledButton>
            </StyledForm>
          ) : (
            <Message>
              Please <StyledLink to="/login">sign in</StyledLink> to write a review
            </Message>
          )}
        </StyledReviewFormContainer>
      
        <h2>Product Reviews</h2>
        {(!product.reviews || product.reviews.length === 0) && <Message>No Reviews</Message>}
        {product.reviews && product.reviews.length > 0 && (
          <StyledReviewsContainer>
            {displayedReviews.map((review) => (
              <ReviewCard key={review._id} review={review} />
            ))}
            {product.reviews.length > 3 && (
              <StyledShowMoreButton onClick={toggleShowAllReviews}>
                {showAllReviews ? "Show Less" : "Show More"}
              </StyledShowMoreButton>
            )}
          </StyledReviewsContainer>
        )}
        
        
      </StyledMoonsSection>
    </>
  );
};

export default ProductPage;