import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const StyledRelatedProducts = styled.div`
  margin-top: 2rem;

  h2 {
    margin-bottom: 1rem;
  }
`;

export const StyledRelatedProduct = styled.div`
  display: inline-block;
  margin-right: 1rem;
  text-align: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
  }

  h3 {
    margin: 0.5rem 0;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const media = {
  desktop: '@media(min-width: 1000px)',
  tablet: '@media(min-width: 600px)',
  phone: '@media(min-width: 300px)',
};

export const StyledMoonsSection = styled.section`
  margin-block: 2em 10em;
  
  h2 {
    font-size: 2rem;
    margin-block: 4em 1em;
    text-align: center;
  }

  @media (min-width: 55em) {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const StyledSuggestionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const StyledHeading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const StyledCartContent = styled.div`
  background-color: #1e2330;
  border-radius: 8px;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const StyledCartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #2a2f3e;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledCartImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const StyledCartItemDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledCartInfo = styled(Link)`
  margin: 0.25rem 0;
  text-decoration: none;
`;

export const StyledCartItemActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }
`;

export const StyledCartQuantity = styled.select`
  background-color: #2a2f3e;
  color: #ffffff;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  margin: 0 0.5rem;
`;

export const StyledCartTotal = styled.div`
  margin-top: 2rem;
  text-align: right;

  h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
`;

export const StyledButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

export const StyledEmptyCart = styled.div`
  background-color: #1e2330;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
`;

export const StyledQuantityAdjust = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  button {
    background-color: #2a2f3e;
    color: #ffffff;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;

    &:hover {
      background-color: #3a3f4e;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const StyledSaveForLater = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-top: 0.5rem;

  &:hover {
    color: #0056b3;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const StyledSuggestionsTitle = styled.h3`
  font-size: 1.5rem;
  margin: 2rem 0 1rem;
`;