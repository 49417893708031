import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://54.196.248.153:3000'
});

const ProductContext = createContext();

export const useProducts = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(16);
  const [successProductReview, setSuccessProductReview] = useState(false);

  const { user } = useUser(); // Get user from UserContext

  const getAuthConfig = useCallback(() => {
    if (!user || !user.token) {
      console.error('No user token found');
      setError('Authentication failed. Please log in again.');
      return null;
    }
    return {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    };
  }, [user]);

  const fetchProducts = useCallback(async (keyword = '', pageNumber = 1, itemsPerPage = 16) => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.get(`/api/products?keyword=${keyword}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`);
      setProducts(data.products);
      setPage(data.page);
      setPages(data.pages);
      setItemsPerPage(itemsPerPage);
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const listProductDetails = async (id) => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.get(`/api/products/${id}`);
      setProduct(data);
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = useCallback(async (id) => {
    const config = getAuthConfig();
    if (!config) return;

    try {
      setLoading(true);
      setError(null);
      await api.delete(`/api/products/${id}`, config);
      setProducts(products.filter(p => p._id !== id));
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  }, [products, getAuthConfig]);

  const createProduct = useCallback(async () => {
    const config = getAuthConfig();
    if (!config) return;

    try {
      setLoading(true);
      setError(null);
      const { data } = await api.post(`/api/products`, {}, config);
      setProducts([...products, data]);
      return data; // Return the created product
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  }, [products, getAuthConfig]);

  const updateProduct = useCallback(async (product) => {
    const config = getAuthConfig();
    if (!config) return;

    try {
      setLoading(true);
      setError(null);
      const { data } = await api.put(`/api/products/${product._id}`, product, config);
      setProducts(products.map(p => p._id === data._id ? data : p));
      setProduct(data);
      return data; // Return the updated product
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  }, [products, getAuthConfig]);

  const createProductReview = async (productId, review) => {
    const config = getAuthConfig();
    try {
      setLoading(true);
      setError(null);
      await api.post(`/api/products/${productId}/reviews`, review, config);
      setSuccessProductReview(true);
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  const listTopProducts = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.get(`/api/products/top`);
      setProducts(data);
    } catch (err) {
      setError(err.response && err.response.data.message ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductContext.Provider value={{ 
      products,
      product,
      loading,
      error,
      page,
      pages,
      itemsPerPage,
      successProductReview,
      fetchProducts,
      listProductDetails,
      deleteProduct,
      createProduct,
      updateProduct,
      createProductReview,
      listTopProducts,
      setItemsPerPage,
      setSuccessProductReview
    }}>
      {children}
    </ProductContext.Provider>
  );
};

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('userInfo');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await api.post('/api/users/login', { email, password }, config);
      setUser(data);
      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    setUser(null);
    // You might want to implement a way to reset other contexts here
    // For example, you could create a custom event and listen for it in other contexts
    window.location.href = '/login';
  };

  const register = async (name, email, password) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await api.post('/api/users', { name, email, password }, config);
      setUser(data);
      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async (id) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.get(`/api/users/${id}`, config);
      return data;
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.data?.message === 'Not authorized, token failed') {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  const updateUserProfile = async (userData) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.put('/api/users/profile', userData, config);
      setUser(data);
      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.data?.message === 'Not authorized, token failed') {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  const listUsers = async () => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.get('/api/users', config);
      setUsers(data);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.data?.message === 'Not authorized, token failed') {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      await api.delete(`/api/users/${id}`, config);
      setUsers(users.filter(u => u._id !== id));
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.data?.message === 'Not authorized, token failed') {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (userData) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.put(`/api/users/${userData._id}`, userData, config);
      setUsers(users.map(u => u._id === userData._id ? data : u));
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.data?.message === 'Not authorized, token failed') {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{
      user,
      users,
      loading,
      error,
      login,
      logout,
      register,
      getUserDetails,
      updateUserProfile,
      listUsers,
      deleteUser,
      updateUser,
    }}>
      {children}
    </UserContext.Provider>
  );
};

const OrderContext = createContext();

export const useOrder = () => useContext(OrderContext);

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, logout } = useUser();

  const createOrder = useCallback(async (order) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.post(`/api/orders`, order, config);
      setOrders([...orders, data]);
      return data;
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [user, orders, logout]);

  const deleteOrder = useCallback(async (id) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      await api.delete(`/api/orders/${id}/delete`, config);
      setOrders(orders.filter(order => order._id !== id));
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [user, orders, logout]);

  const getOrderDetails = useCallback(async (id) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.get(`/api/orders/${id}`, config);
      return data;
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [user, logout]);

  const payOrder = async (orderId, paymentResult) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      };
  
      const { data } = await api.put(
        `/api/orders/${orderId}/pay`,
        paymentResult,
        config
      );
      return data;
    } catch (error) {
      console.error('Error paying order:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  const deliverOrder = useCallback(async (order) => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.put(`/api/orders/${order._id}/deliver`, {}, config);
      setOrders(orders.map(o => o._id === order._id ? data : o));
      return data;
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [user, orders, logout]);

  const listMyOrders = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.get(`/api/orders/myorders`, config);
      setOrders(data);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [user, logout]);

  const listOrders = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await api.get(`/api/orders`, config);
      setOrders(data);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [user, logout]);

  return (
    <OrderContext.Provider value={{
      user,
      orders,
      loading,
      error,
      createOrder,
      deleteOrder,
      getOrderDetails,
      payOrder,
      deliverOrder,
      listMyOrders,
      listOrders
    }}>
      {children}
    </OrderContext.Provider>
  );
};

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    // Try to get cart items from localStorage on initial load
    const savedCartItems = localStorage.getItem('cartItems');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [shippingAddress, setShippingAddress] = useState(() => {
    const savedAddress = localStorage.getItem('shippingAddress');
    return savedAddress ? JSON.parse(savedAddress) : {};
  });
  const [paymentMethod, setPaymentMethod] = useState(() => {
    return localStorage.getItem('paymentMethod') || '';
  });

  // Update localStorage whenever cartItems change
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem('shippingAddress', JSON.stringify(shippingAddress));
  }, [shippingAddress]);

  useEffect(() => {
    localStorage.setItem('paymentMethod', paymentMethod);
  }, [paymentMethod]);

  const addToCart = async (id, qty) => {
    try {
      const { data } = await api.get(`/api/products/${id}`);
      const itemToAdd = {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        countInStock: data.countInStock,
        qty: Math.min(qty, data.countInStock), // Ensure qty doesn't exceed countInStock
      };

      setCartItems(prevItems => {
        const existItem = prevItems.find(x => x.product === itemToAdd.product);
        if (existItem) {
          return prevItems.map(x => 
            x.product === existItem.product ? 
            { ...x, qty: Math.min(itemToAdd.qty, x.countInStock) } : x
          );
        }
        return [...prevItems, itemToAdd];
      });

      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const removeFromCart = (id) => {
    setCartItems(prevItems => prevItems.filter(x => x.product !== id));
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  };

  const saveShippingAddress = (data) => {
    setShippingAddress(data);
  };

  const savePaymentMethod = (data) => {
    setPaymentMethod(data);
  };

  return (
    <CartContext.Provider 
      value={{
        cartItems,
        addToCart,
        clearCart,
        removeFromCart,
        shippingAddress,
        saveShippingAddress,
        paymentMethod,
        savePaymentMethod,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};