import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useProducts, useUser } from '../../context/Context';
import Paginate from "../../components/Paginater/Paginate";
import styled from 'styled-components';
import { FaEdit, FaTrash, FaPlus, FaSearch, FaTimes, FaUpload } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const ProductManagement = () => {
  const { user } = useUser();
  const { keyword: urlKeyword = '', pageNumber = 1 } = useParams();
  const [searchKeyword, setSearchKeyword] = useState(urlKeyword)
  const { products, fetchProducts, createProduct, updateProduct, deleteProduct, page, pages, itemsPerPage, setItemsPerPage } = useProducts();
  const [localItemsPerPage, setLocalItemsPerPage] = useState(itemsPerPage);
  const [editingProduct, setEditingProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    name: '',
    partNum: '',
    price: '',
    description: '',
    image: [],
    brand: '',
    category: '',
    countInStock: ''
  });
  const [isCreating, setIsCreating] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const topRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    fetchProducts(urlKeyword, pageNumber, localItemsPerPage);
    setSearchKeyword(urlKeyword);
  }, [fetchProducts, urlKeyword, pageNumber, localItemsPerPage, location.pathname]);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setLocalItemsPerPage(newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKeyword.trim()) {
      navigate(`/admin/products/search/${searchKeyword.trim()}`);
    } else {
      navigate('/admin/products');
    }
  };
  
  const handleImageDelete = (index, setter) => {
    setter(prev => {
      const newImages = [...prev.image];
      newImages.splice(index, 1);
      return { ...prev, image: newImages };
    });
    setImagePreview(prev => {
      const newPreviews = [...prev];
      newPreviews.splice(index, 1);
      return newPreviews;
    });
  };

  const getAuthConfig = useCallback(() => {
    if (!user || !user.token) {
      console.error('No user token found');
      setError('Authentication failed. Please log in again.');
      return null;
    }
    return {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    };
  }, [user]);

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setIsCreating(false);
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDeleteProduct = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      const config = getAuthConfig();
      if (!config) return;

      try {
        await deleteProduct(id);
        setSuccess('Product deleted successfully');
        fetchProducts();
        window.location.reload();
      } catch (err) {
        console.error('Error deleting product:', err);
        setError(err.response?.data?.message || 'Error deleting product');
      }
    }
  };

  const uploadFileHandler = async (e, setter) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    
    files.forEach((file, index) => {
      formData.append(`image`, file);
    });

    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post('/api/upload', formData, config);

      setter(prev => ({ ...prev, image: [...prev.image, ...data] }));
      setImagePreview(prev => [...prev, ...data]);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const createdProduct = await createProduct();
      const updatedProduct = await updateProduct({
        ...createdProduct,
        ...newProduct
      });
      console.log('Product created and updated:', updatedProduct);
      setSuccess('Product created successfully');
      fetchProducts();
      setNewProduct({
        name: '',
        partNum: '',
        price: '',
        description: '',
        image: [],
        brand: '',
        category: '',
        countInStock: ''
      });
      setIsCreating(false);
      window.location.reload();
    } catch (err) {
      setError('Error creating product');
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    const config = getAuthConfig();
    if (!config) return;
  
    try {
      // Join the image array into a comma-separated string
      const updatedProductData = {
        ...editingProduct,
        image: Array.isArray(editingProduct.image) 
          ? editingProduct.image 
          : editingProduct.image.split(',').map(img => img.trim())
      };
  
      const { data: updatedProduct } = await updateProduct(updatedProductData);
      
      console.log('Product updated:', updatedProduct);
      setSuccess('Product updated successfully');
      fetchProducts();
      setEditingProduct(null);
    } catch (err) {
      console.error('Error updating product:', err);
      setError(err.response?.data?.message || 'Error updating product');
    }
  };

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter(prev => ({ ...prev, [name]: value }));
  };

  const getFullImageUrl = useCallback((imageUrl) => {
    return `${process.env.REACT_APP_API_URL || ''}${imageUrl}`;
  }, []);

  return (
    <StyledContainer>
      <div ref={topRef} />
      <Header>
        <h1>Product Management</h1>
        <StyledButton onClick={() => { setIsCreating(true); setEditingProduct(null); }}>
          <FaPlus /> Create New Product
        </StyledButton>
      </Header>
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}

      <ContentGrid>
        <ProductForm>
          <FormHeader>
            {isCreating ? (
              <h2><FaPlus /> Create New Product</h2>
            ) : editingProduct ? (
              <h2><FaEdit /> Edit Product: {editingProduct.name}</h2>
            ) : (
              <h2>Product Actions</h2>
            )}
          </FormHeader>
          {isCreating || editingProduct ? (
            <FormContent>
              <StyledForm onSubmit={isCreating ? handleCreateProduct : handleUpdateProduct}>
                <FormGroup>
                  <StyledLabel htmlFor="name">Product Name</StyledLabel>
                  <StyledInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter product name"
                    value={isCreating ? newProduct.name : editingProduct.name}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="name">Part Number</StyledLabel>
                  <StyledInput
                    id="partNum"
                    name="partNum"
                    type="text"
                    placeholder="Enter Prat Number"
                    value={isCreating ? newProduct.partNum : editingProduct.partNum}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="price">Price ($)</StyledLabel>
                  <StyledInput
                    id="price"
                    name="price"
                    type="number"
                    placeholder="Enter price"
                    value={isCreating ? newProduct.price : editingProduct.price}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="description">Description</StyledLabel>
                  <StyledTextArea
                    id="description"
                    name="description"
                    placeholder="Enter product description"
                    value={isCreating ? newProduct.description : editingProduct.description}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="image">Product Images</StyledLabel>
                  <ImageUploadContainer>
                    {(isCreating ? newProduct.image : editingProduct.image).map((image, index) => (
                      <ImagePreviewContainer key={index}>
                        <ImagePreview src={getFullImageUrl(image)} alt={`Product ${index + 1}`} />
                        <DeleteImageButton onClick={() => handleImageDelete(index, isCreating ? setNewProduct : setEditingProduct)}>
                          <FaTimes />
                        </DeleteImageButton>
                      </ImagePreviewContainer>
                    ))}
                    <FileInputLabel>
                      <FileInput
                        type="file"
                        onChange={(e) => uploadFileHandler(e, isCreating ? setNewProduct : setEditingProduct)}
                        accept="image/*"
                        multiple
                      />
                      <FaUpload /> Choose Images
                    </FileInputLabel>
                  </ImageUploadContainer>
                  {uploading && <UploadingText>Uploading...</UploadingText>}
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="brand">Brand</StyledLabel>
                  <StyledInput
                    id="brand"
                    name="brand"
                    type="text"
                    placeholder="Enter brand"
                    value={isCreating ? newProduct.brand : editingProduct.brand}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="category">Category</StyledLabel>
                  <StyledInput
                    id="category"
                    name="category"
                    type="text"
                    placeholder="Enter category"
                    value={isCreating ? newProduct.category : editingProduct.category}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel htmlFor="countInStock">Count in Stock</StyledLabel>
                  <StyledInput
                    id="countInStock"
                    name="countInStock"
                    type="number"
                    placeholder="Enter stock quantity"
                    value={isCreating ? newProduct.countInStock : editingProduct.countInStock}
                    onChange={(e) => handleInputChange(e, isCreating ? setNewProduct : setEditingProduct)}
                    required
                  />
                </FormGroup>
                <ButtonContainer>
                  <StyledButton type="submit">{isCreating ? 'Create Product' : 'Update Product'}</StyledButton>
                  <StyledButton onClick={() => isCreating ? setIsCreating(false) : setEditingProduct(null)} secondary>Cancel</StyledButton>
                </ButtonContainer>
              </StyledForm>
            </FormContent>
          ) : (
            <EmptyState>
              Select a product to edit or click "Create New Product" to add a new item.
            </EmptyState>
          )}
        </ProductForm>

        <ProductList>
          <h2>Product List</h2>
          <SearchForm onSubmit={handleSearch}>
            <SearchInput
              type="text"
              placeholder="Search products..."
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <SearchButton type="submit">
              <FaSearch />
              {searchKeyword.trim() ? "Search" : "Show All"}
            </SearchButton>
          </SearchForm>
          {products.length === 0 ? (
            <EmptyState>No products found. Create a new product or adjust your search.</EmptyState>
          ) : (
            <StyledTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Brand</th>
                  <th>Category</th>
                  <th>Stock</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product._id}>
                    <td>{product.name}</td>
                    <td>${product.price}</td>
                    <td>{product.brand}</td>
                    <td>{product.category}</td>
                    <td>{product.countInStock}</td>
                    <td>
                      <IconButton onClick={() => handleEditProduct(product)} title="Edit">
                        <FaEdit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteProduct(product._id)} title="Delete">
                        <FaTrash />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          )}
        </ProductList>
        <Paginate
          pages={pages}
          page={page}
          isAdmin={true}
          keyword={urlKeyword ? urlKeyword : ""}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </ContentGrid>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h1 {
    font-size: 2rem;
    color: #ffffff;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
`;

const ProductList = styled.div`
  h2 {
    margin-bottom: 1rem;
    color: #ffffff;
  }
`;

const ProductForm = styled.div`
  background-color: #1e2330;
  border-radius: 8px;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const FormHeader = styled.div`
  background-color: #2a2f3e;
  padding: 1rem 1.5rem;

  h2 {
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
  }
`;

const FormContent = styled.div`
  padding: 1.5rem;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #1e2330;
  border-radius: 8px;
  overflow: hidden;

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #2a2f3e;
  }

  th {
    background-color: #2a2f3e;
    font-weight: bold;
    color: #ffffff;
  }

  td {
    color: #cccccc;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const StyledButton = styled.button`
  background-color: ${props => props.secondary ? '#6c757d' : '#4a90e2'};
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${props => props.secondary ? '#5a6268' : '#357abd'};
  }
`;

const IconButton = styled.button`
  background-color: transparent;
  color: #4a90e2;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 1rem;
  padding: 0.25rem;
  margin-right: 0.5rem;

  &:hover {
    color: #357abd;
  }
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #2a2f3e;
  border-radius: 8px;
  color: #6c757d;
  text-align: center;
  padding: 1rem;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  padding-right: 2.5rem;
  border: 1px solid #2a2f3e;
  border-radius: 4px;
  background-color: #2a2f3e;
  color: #ffffff;
`;

const SearchIcon = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
  color: #ffffff;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const StyledInput = styled.input`
  padding: 0.75rem;
  border: 1px solid #2a2f3e;
  border-radius: 4px;
  background-color: #2a2f3e;
  color: #ffffff;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const StyledTextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #2a2f3e;
  border-radius: 4px;
  background-color: #2a2f3e;
  color: #ffffff;
  min-height: 100px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const ErrorMessage = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
  border: 2px dashed #4a90e2;
  border-radius: 4px;
  overflow: hidden;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2f3e;
  color: #6c757d;
`;

const DeleteImageButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(220, 53, 69, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(200, 35, 51, 1);
  }
`;

const FileInputLabel = styled.label`
  display: inline-block;
  background-color: #4a90e2;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #357abd;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const UploadingText = styled.p`
  color: #4a90e2;
  margin-top: 0.5rem;
`;

const SearchForm = styled.form`
  position: relative;
  margin-bottom: 1rem;
  display: flex;
`;

export const SearchButton = styled.button`
  padding: 0.5em 1em;
  font-size: 1rem;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;

  &:hover {
    background-color: #357abd;
  }

  svg {
    margin-right: 0.5em;
  }

  @media (max-width: 768px) {
    padding: 0.5em;
    
    span {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
`;

export default ProductManagement;