import React from 'react';
import { PaginationContainer, PageButtonsContainer, PageButton, Ellipsis, ItemsPerPageSelect } from './StyledPaginate';

const Paginate = ({ pages, page, isAdmin = false, keyword = '', itemsPerPage, onItemsPerPageChange }) => {
  const getPageUrl = (pageNumber) => {
    if (isAdmin && keyword) return `/admin/products/search/${keyword}/${pageNumber}`;
    if (isAdmin && !keyword) return `/admin/products/${pageNumber}`;
    if (!isAdmin && keyword ) return `/search/${keyword}/page/${pageNumber}`;
    return `/page/${pageNumber}`;
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const rangeStart = Math.max(1, page - 2);
    const rangeEnd = Math.min(pages, page + 2);

    if (rangeStart > 1) {
      pageNumbers.push(1);
      if (rangeStart > 2) pageNumbers.push('...');
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbers.push(i);
    }

    if (rangeEnd < pages) {
      if (rangeEnd < pages - 1) pageNumbers.push('...');
      pageNumbers.push(pages);
    }

    return pageNumbers.map((num, index) => 
      num === '...' ? (
        <Ellipsis key={`ellipsis-${index}`}>...</Ellipsis>
      ) : (
        <PageButton 
          key={num} 
          to={getPageUrl(num)}
          className={num === page ? 'active' : ''}
        >
          {num}
        </PageButton>
      )
    );
  };

  return (
    <PaginationContainer>
      <PageButtonsContainer>
        {pages > 1 && (
          <PageButton 
            to={getPageUrl(page - 1)} 
            className={page <= 1 ? 'disabled' : ''}
          >
            Prev
          </PageButton>
        )}
        {renderPageNumbers()}
        {pages > 1 && (
          <PageButton 
            to={getPageUrl(page + 1)} 
            className={page >= pages ? 'disabled' : ''}
          >
            Next
          </PageButton>
        )}
      </PageButtonsContainer>
      <ItemsPerPageSelect 
        value={itemsPerPage} 
        onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
      >
        <option value={16}>16 per page</option>
        <option value={32}>32 per page</option>
        <option value={48}>48 per page</option>
      </ItemsPerPageSelect>
    </PaginationContainer>
  );
};

export default Paginate;