import React, { useState, useEffect, useCallback } from 'react';
import { useOrder, useUser } from '../../context/Context';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaSearch, FaCheck, FaTimes, FaTruck } from 'react-icons/fa';
import axios from 'axios';

const TrackingPopup = ({ isOpen, onClose, onSubmit }) => {
    const [trackingNumber, setTrackingNumber] = useState('');
  
    if (!isOpen) return null;
  
    return (
      <PopupOverlay>
        <PopupContent>
          <h2>Enter Tracking Number</h2>
          <Input
            type="text"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            placeholder="Enter tracking number"
          />
          <ButtonGroup>
            <Button onClick={() => onSubmit(trackingNumber)}>Submit</Button>
            <Button secondary onClick={onClose}>Cancel</Button>
          </ButtonGroup>
        </PopupContent>
      </PopupOverlay>
    );
  };

const AdminOrders = () => {
  const { listOrders, deliverOrder, deleteOrder, orders, loading, error } = useOrder();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const { user } = useUser();

  const getAuthConfig = useCallback(() => {
    if (!user || !user.token) {
      console.error('No user token found');
      return null;
    }
    return {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    };
  }, [user]);

  useEffect(() => {
    listOrders();
  }, [listOrders]);

  useEffect(() => {
    if (orders) {
      setFilteredOrders(
        orders.filter((order) =>
          order._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.user.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [orders, searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    // The filtering is already done in the useEffect above
  };

  const handleDeliverClick = (orderId) => {
    setCurrentOrderId(orderId);
    setIsPopupOpen(true);
  };

  const handleTrackingSubmit = async (trackingNumber) => {
    if (window.confirm('Are you sure you want to mark this order as delivered?')) {
      try {
        const updatedOrder = await deliverOrder({ _id: currentOrderId, trackingNumber });
        await sendShippingConfirmationEmail(updatedOrder, trackingNumber);
        listOrders(); // Refresh the orders list
      } catch (error) {
        console.error('Error updating order or sending email:', error);
        // Handle error (e.g., show error message to admin)
      }
    }
    setIsPopupOpen(false);
  };

  const sendShippingConfirmationEmail = async (order, trackingNumber) => {
    try {
        const config = getAuthConfig();
      if (!config) {
        console.error('Failed to get auth config');
        return; // Exit if we can't get the auth config
      }

      await axios.post('/api/email/shipping-confirmation', {
        order,
        trackingNumber,
      }, config);
      
      console.log('Shipping confirmation email sent');
    } catch (error) {
      console.error('Error sending shipping confirmation email:', error);
      // Handle error (e.g., show error message to admin)
    }
  };

  const handleDelete = async (orderId) => {
    if (window.confirm('Are you sure you want to delete this order? This action cannot be undone.')) {
      await deleteOrder(orderId);
      listOrders(); // Refresh the orders list
    }
  };

  if (loading) return <LoadingMessage>Loading orders...</LoadingMessage>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <Container>
      <Header>
        <h1>Order Management</h1>
      </Header>

      <SearchForm onSubmit={handleSearch}>
        <SearchInput
          type="text"
          placeholder="Search orders by ID or customer name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchButton type="submit">
          <FaSearch />
          Search
        </SearchButton>
      </SearchForm>

      {filteredOrders.length === 0 ? (
        <EmptyState>No orders found.</EmptyState>
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Date</th>
              <th>Total</th>
              <th>Paid</th>
              <th>Shipped</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.user.name}</td>
                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                <td>${order.totalPrice.toFixed(2)}</td>
                <td>
                  {order.isPaid ? (
                    <StatusIcon green><FaCheck /></StatusIcon>
                  ) : (
                    <StatusIcon red><FaTimes /></StatusIcon>
                  )}
                </td>
                <td>
                  {order.isDelivered ? (
                    <StatusIcon green><FaCheck /></StatusIcon>
                  ) : (
                    <StatusIcon red><FaTimes /></StatusIcon>
                  )}
                </td>
                <td>
                <ActionButton as={Link} to={`/order/${order._id}`} title="View Details">
                  <FaEdit />
                </ActionButton>
                {!order.isDelivered && (
                  <ActionButton onClick={() => handleDeliverClick(order._id)} title="Mark as Shipped">
                    <FaTruck />
                  </ActionButton>
                )}
                <ActionButton onClick={() => handleDelete(order._id)} title="Delete Order">
                  <FaTrash />
                </ActionButton>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      )}
      <TrackingPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onSubmit={handleTrackingSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.header`
  margin-bottom: 2rem;

  h1 {
    font-size: 2rem;
    color: #ffffff;
  }
`;

const SearchForm = styled.form`
  display: flex;
  margin-bottom: 2rem;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #2a2f3e;
  border-radius: 4px 0 0 4px;
  background-color: #1e2330;
  color: #ffffff;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #357abd;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #1e2330;
  border-radius: 8px;
  overflow: hidden;

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #2a2f3e;
  }

  th {
    background-color: #2a2f3e;
    font-weight: bold;
    color: #ffffff;
  }

  td {
    color: #cccccc;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: #4a90e2;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 1rem;
  padding: 0.25rem;
  margin-right: 0.5rem;

  &:hover {
    color: #357abd;
  }
`;

const StatusIcon = styled.span`
  color: ${props => props.green ? '#28a745' : props.red ? '#dc3545' : 'inherit'};
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #ffffff;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #dc3545;
`;

const EmptyState = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: #6c757d;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: #1e2330;
  padding: 2rem;
  border-radius: 8px;
  width: 600px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #2a2f3e;
  border: 1px solid #4a4f5e;
  color: #ffffff;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.secondary ? '#6c757d' : '#4a90e2'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.secondary ? '#5a6268' : '#357abd'};
  }
`;

export default AdminOrders;