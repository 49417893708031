import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const StyledHeading = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const StyledOrderInfo = styled.div`
  background-color: #1e2330;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 10px;
  }
`;

export const StyledOrderItems = styled.div`
  margin-bottom: 20px;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 8px; // This line adds rounded corners to the image
  }

  a {
    flex: 1;
    text-decoration: none;
    color: #007bff;
  }
`;

export const StyledOrderSummary = styled.div`
  background-color: #1e2330;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 10px;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

export const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;