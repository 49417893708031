import styled from "styled-components";

export const StyledCard = styled.div`
  background-color: #141823;
  padding: 0.75rem 1rem; // Reduced padding top and bottom
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  margin-bottom: 0.75rem; // Reduced margin between cards
`;

export const StyledReviewerName = styled.h4`
  font-size: 1.2rem;
  margin-bottom: 0.25rem; // Reduced space below the name
  margin-top: 0.75rem;
`;

export const StyledRating = styled.div`
  margin-bottom: 0.25rem; // Reduced space below the rating
`;

export const StyledReviewDate = styled.p`
  font-size: 0.8rem;
  color: #888;
  margin-bottom: 0.25rem; // Reduced space below the date
`;

export const StyledReviewComment = styled.p`
  font-size: 1rem;
  line-height: 1.3;
  margin: 0; // Remove default margins
`;