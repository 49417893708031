import {
  StyledCard,
  StyledCelestialObject,
  StyledDescription,
  StyledButton,
  StyledButton2,
  StyledButton3
} from "./StyledCelestialObjectCard";

const CelestialObjectCard = ({
  id,
  className,
  color,
  name,
  description,
  to
}) => {
  let ButtonComponent;

  // Check if id starts with SLT-00, SLT-01, or SLT-02
  if (id && id.startsWith('SLT-00')) {
    ButtonComponent = StyledButton;
  } else if (id && id.startsWith('SLT-01')) {
    ButtonComponent = StyledButton2;
  } else if (id && id.startsWith('SLT-02')) {
    ButtonComponent = StyledButton3;
  } else {
    // Set a default button component if id doesn't match any condition
    ButtonComponent = StyledButton;
  }

  return (
    <StyledCard className={className}>
      <StyledCelestialObject>
        <img src={color} alt="img" />
      </StyledCelestialObject>
      <StyledDescription>{description}</StyledDescription>
      <ButtonComponent to={to || '/'}>{name}</ButtonComponent>
    </StyledCard>
  );
};

export default CelestialObjectCard;