import styled, { createGlobalStyle } from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const StyledSummary = styled.div`
  background-color: #1e2330;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export const StyledStep = styled.div`
  margin-bottom: 2rem;
`;

export const StyledPayPalContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledOrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  
  span {
    flex: 1;
    &:last-child {
      text-align: right;
    }
  }
`;

export const StyledDebugInfo = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: #1e2330;
  border: 1px solid #ccc;
  border-radius: 4px;

  h3 {
    margin-top: 0;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const StyledAddressSuggestions = styled.div`
  position: absolute;
  background-color: #1e2330;
  border: 1px solid #ddd;
  border-top: none;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 60px;

  div {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

export const GoogleAutoCompleteStyle = createGlobalStyle`
  .pac-container {
    background-color: #f8f9fa;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #e0e0e0;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
    z-index: 1000;
  }

  .pac-item {
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .pac-item-selected {
    background-color: #e9ecef;
  }

  .pac-matched {
    font-weight: bold;
  }

  .pac-icon {
    display: none;
  }
`;

export const StyledAddressInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledAddressInput = styled(StyledInput)`
  width: 100%;
`;

export const StyledShippingOptions = styled.div`
  margin-bottom: 20px;
`;

export const StyledShippingOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
  }

  label {
    display: flex;
    flex-direction: column;
  }

  span {
    margin-bottom: 5px;
  }
`;

export const StyledWarning = styled.p`
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
`;
