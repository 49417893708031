import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useUser } from '../../context/Context';
import { useCart } from '../../context/Context';
import {
  NavbarContainer,
  NavbarContent,
  Logo,
  NavLinks,
  NavLink,
  CartButton,
  CartCount,
  BurgerButton
} from "./NavbarStyles";
import cartIcon from "./img/shopping-cart.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const { cartItems } = useCart();
  const [cartItemCount, setCartItemCount] = useState(0);
  const menuRef = useRef(null);

  useEffect(() => {
    const totalItems = cartItems.reduce((total, item) => total + item.qty, 0);
    setCartItemCount(totalItems);
  }, [cartItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <NavbarContainer>
      <NavbarContent>
        <NavLinks isOpen={isOpen} ref={menuRef}>
          <NavLink as={Link} to="/"onClick={handleLinkClick}>HOME</NavLink>
          <NavLink as={Link} to="/services"onClick={handleLinkClick}>SERVICES</NavLink>
          <NavLink as={Link} to="/shop"onClick={handleLinkClick}>SHOP</NavLink>
          <NavLink as={Link} to="/about"onClick={handleLinkClick}>ABOUT</NavLink>
          <NavLink as={Link} to="/contact"onClick={handleLinkClick}>CONTACT</NavLink>
          {user ? (
            <NavLink as={Link} to="/profile">
              {user.name.toUpperCase()}
            </NavLink>
          ) : (
            <NavLink as={Link} to="/login">
              LOGIN
            </NavLink>
          )}
        </NavLinks>
        <CartButton as={Link} to="/cart">
          <img src={cartIcon} alt="cart" height="20px" width="20px" />
          {cartItemCount > 0 && <CartCount>{cartItemCount}</CartCount>}
        </CartButton>
        <BurgerButton onClick={toggleMenu}>☰</BurgerButton>
      </NavbarContent>
    </NavbarContainer>
  );
};

export default Navbar;
