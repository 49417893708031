import styled from "styled-components";

export const StyledHeader = styled.header`
  margin-block: 0;
  
  h1 {
    font-size: 2rem;
    margin: 0;
    padding-left: 0; // Remove left padding as it's now in a flex container
  }

  p {
    margin-block-start: .8em;
    margin: 0;
  }

  @media (min-width: 48em) {
    margin-block: 0; // Adjust this to fit within the header container
  }
`;